import axios from 'axios';
import { apiProvider, config, interceptor } from './common';

const API = axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_API,
});

interceptor(API);

export const getLevelByIdAPI = (id) => {
  return apiProvider(API).request({ method: 'get', endpoint: `level/${id}`, cfg: config() });
};

export const getUserByIdAPI = (id) => {
  return apiProvider(API).request({ method: 'get', endpoint: `user/${id}`, cfg: config() });
};

export const getLevelSettingsByTypeAPI = (type) => {
  return apiProvider(API).request({ method: 'get', endpoint: `level_settings/${type}`, cfg: config() });
};

export const checkUserAPI = (request) => {
  return apiProvider(API).request({ method: 'post', endpoint: 'check_user', request, cfg: config() });
};

export const saveUserScoreAPI = (request) => {
  return apiProvider(API).request({ method: 'post', endpoint: 'save_score', request, cfg: config() });
};

export const getTopUsersScoreAPI = () => {
  return apiProvider(API).request({ method: 'get', endpoint: 'top_users_score', cfg: config() });
};

export const getMyScoreAPI = (id) => {
  return apiProvider(API).request({ method: 'get', endpoint: `my_score?id=${id}`, cfg: config() });
};
