import React from 'react';
import { observer } from 'mobx-react';
import { appStore } from '../../common/stores/AppStore';
import Animation from '../../components/animations/Animation';
import Text from '../../components/gameItems/Text';
import Button from '../../components/buttons/Button';
import { COLORS } from '../../components/_settings/colors';
import ScoreContainer from '../../components/containers/ScoreContainer';
import Box from '../../components/containers/Box';

const CurrentScore = observer(function CurrentScores() {
  const { currentGameScore, clearCurrentGameScore, setAppMode } = appStore || {};
  const [score, setScore] = React.useState({ objects: 0, time: 0, totalScore: 0 });
  const [showRows, setShowRows] = React.useState({ score: false, time: false, total: false });

  React.useEffect(() => {
    return () => {
      clearCurrentGameScore();
    };
  }, []);

  React.useEffect(() => {
    setShowRows({ score: true, time: false, total: false });
    new Promise((resolve) => {
      const timeInterval = 1000 / (currentGameScore.foundObjects === 0 ? 1000 : currentGameScore.foundObjects);
      const interval = setInterval(() => {
        setScore((prevState) => {
          if (currentGameScore.foundObjects === prevState.objects) {
            clearInterval(interval);
            resolve();
            return prevState;
          }
          return { ...prevState, objects: prevState.objects + 1 };
        });
      }, timeInterval);
    })
      .then(() => {
        setShowRows({ score: true, time: true, total: false });
        return new Promise((resolve) => {
          const timeInterval = 1000 / (currentGameScore.timeLeft === 0 ? 1000 : currentGameScore.timeLeft);
          const interval = setInterval(() => {
            setScore((prevState) => {
              if (currentGameScore.timeLeft === prevState.time) {
                clearInterval(interval);
                resolve();
                return prevState;
              }
              return { ...prevState, time: prevState.time + 1 };
            });
          }, timeInterval);
        });
      })
      .then(() => {
        setShowRows({ score: true, time: true, total: true });
        const timeInterval = 1000 / (currentGameScore.totalScore === 0 ? 1000 : currentGameScore.totalScore);
        const interval = setInterval(() => {
          setScore((prevState) => {
            if (currentGameScore.totalScore === prevState.totalScore) {
              clearInterval(interval);
              return prevState;
            }
            return { ...prevState, totalScore: prevState.totalScore + 1 };
          });
        }, timeInterval);
      });
  }, [currentGameScore]);

  return (
    <Animation inherit>
      <ScoreContainer
        button={showRows.total && <Button text="В меню" onClick={() => setAppMode('MENU')} style={{ width: '100%', maxWidth: '500px' }} />}
        title={
          <Text style={{ marginBottom: '12px' }} fontSize="32px" fontWeight={600} color={COLORS.green1}>
            Уровень завершен
          </Text>
        }
      >
        <Box direction="column" style={{ margin: 'auto' }}>
          {showRows.score && (
            <>
              {!currentGameScore?.complete && (
                <Text style={{ marginBottom: '8px' }} fontSize="24px" fontWeight={600} color={COLORS.blue1}>
                  Осталось попыток - {currentGameScore?.attempts ?? '-'}
                </Text>
              )}
              <Text style={{ marginBottom: '8px' }} fontSize="24px" fontWeight={600} color={COLORS.blue1}>
                Найдено объектов - {score?.objects ?? '-'}
              </Text>
            </>
          )}
          {showRows.time && (
            <Text style={{ marginBottom: '8px' }} fontSize="24px" fontWeight={600} color={COLORS.blue1}>
              Осталось времени - {score?.time ?? '-'} сек.
            </Text>
          )}
          {showRows.total && (
            <Text style={{ marginBottom: '12px', height: '100%' }} fontSize="36px" fontWeight={800} color={COLORS.red1}>
              Очков за уровень - {score?.totalScore ?? '-'}
            </Text>
          )}
        </Box>
      </ScoreContainer>
    </Animation>
  );
});

export default CurrentScore;
