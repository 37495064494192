import PropTypes from 'prop-types';
import classes from './css/menuContainer.module.scss';

function MenuContainer({ children }) {
  return <article className={classes.menuContainer}>{children}</article>;
}

MenuContainer.propTypes = {
  children: PropTypes.node,
};

MenuContainer.defaultProps = {
  children: null,
};
export default MenuContainer;
