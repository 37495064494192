import React from 'react';
import { observer } from 'mobx-react';
import Animation from '../../components/animations/Animation';
import Text from '../../components/gameItems/Text';
import Button from '../../components/buttons/Button';
import { COLORS } from '../../components/_settings/colors';
import ScoreContainer from '../../components/containers/ScoreContainer';
import Box from '../../components/containers/Box';
import ScrollBox from '../../components/containers/ScrollBox';
import { appStore } from '../../common/stores/AppStore';

const TotalScore = observer(function TotalScore() {
  const { setAppMode } = appStore || {};

  return (
    <Animation inherit>
      <ScoreContainer button={<Button text="В меню" style={{ width: '100%', maxWidth: '600px' }} onClick={() => setAppMode('MENU')} />}>
        <Box
          style={{
            width: '100%',
            height: '100%',
            maxWidth: '600px',
            margin: '0 auto',
          }}
          direction="column"
        >
          <ScrollBox p="20px 0 20px 0">
            <Text style={{ marginBottom: '12px' }} fontSize="24px" fontWeight={600} color={COLORS.green1}>
              Механика игры
            </Text>
            <Text style={{ textAlign: 'justify', marginBottom: '20px' }}>
              Задача: внизу экрана расположены объекты, которые необходимо найти на интерактивной карте. Каждый участник изначально получает 3
              попытки, каждая из которых длится по 60 секунд.
            </Text>
            <Text style={{ marginBottom: '12px' }} fontSize="24px" fontWeight={600} color={COLORS.green1}>
              Смена объектов
            </Text>
            <Text style={{ textAlign: 'justify', marginBottom: '20px' }}>
              Объекты на карте меняются. Участник, получив уведомление в чат-боте о смене объектов, может использовать оставшиеся попытки. Далее
              объекты будут изменены: дополнительно участник получит еще 3 попытки длительностью 60 секунд. Каждая смена объектов (карты) происходит
              строго после уведомления в чат-боте.{' '}
            </Text>
            <Text style={{ marginBottom: '12px' }} fontSize="24px" fontWeight={600} color={COLORS.green1}>
              Баллы
            </Text>
            <Text style={{ textAlign: 'justify', marginBottom: '20px' }}>
              Один найденный объект — один балл. Сумма всех баллов за каждую попытку суммируется. Если все объекты найдены, то участник дополнительно
              получает по одному баллу за каждую неиспользованную секунду. Сумма баллов отображается в личном кабинете игры.{' '}
            </Text>
            <Text style={{ marginBottom: '12px' }} fontSize="24px" fontWeight={600} color={COLORS.green1}>
              Топ игроков
            </Text>
            <Text style={{ textAlign: 'justify', marginBottom: '20px' }}>
              Находя объекты, участник получает баллы. Цель: набрать как можно больше баллов, чтобы занять с 1 по 10 место в общем «Топе игроков».{' '}
            </Text>
            <Text style={{ marginBottom: '12px' }} fontSize="24px" fontWeight={600} color={COLORS.green1}>
              Выбор победителей
            </Text>
            <Text style={{ textAlign: 'justify', marginBottom: '20px' }}>
              Каждый первый день нового месяца определяется первая десятка участников рейтинга. Каждый, кто занял с 1-го по 10-е место получает приз.
              О том, что участник выиграл, будет проинформировано в персональном уведомлении от чат-бота.{' '}
            </Text>
          </ScrollBox>
        </Box>
      </ScoreContainer>
    </Animation>
  );
});

export default TotalScore;
