import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';

const lodashMethods = {
  set,
  get,
  isEqual,
  sortBy,
  isEmpty,
  cloneDeep,
  isObject,
};

export default lodashMethods;
