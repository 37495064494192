import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classes from './css/box.module.scss';

const Box = observer(function Box({ direction, justify, align, style, children, spacing }) {
  return (
    <div className={classes.box} style={{ ...style, flexDirection: direction, justifyContent: justify, alignItems: align }}>
      {children &&
        React.Children.map(children, (el) => {
          if (!el) return null;
          if (!spacing) return el;
          return React.cloneElement(el, {
            style: { ...el?.props?.style, margin: spacing },
          });
        })}
    </div>
  );
});

Box.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.string,
  justify: PropTypes.string,
  spacing: PropTypes.string,
  align: PropTypes.string,
  style: PropTypes.objectOf(Object),
};

Box.defaultProps = {
  children: null,
  direction: null,
  justify: null,
  align: null,
  spacing: null,
  style: null,
};
export default Box;
