import React from 'react';

let timeout;

function useOrientationHook() {
  const [orientation, setOrientation] = React.useState(null);
  React.useEffect(() => {
    checkOrientation();
    window.addEventListener('resize', () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        checkOrientation();
      }, 300);
    });
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  const checkOrientation = () => {
    setOrientation({
      landscape: window.innerWidth / window.innerHeight > 1,
      dimensions: `${window.innerWidth},${window.innerHeight}`,
    });
  };

  return { orientation };
}

export default useOrientationHook;
