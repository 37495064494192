import { observer } from 'mobx-react';
import MenuContainer from '../../components/containers/MenuContainer';
import Button from '../../components/buttons/Button';
import { appStore } from '../../common/stores/AppStore';
import Animation from '../../components/animations/Animation';
import Text from '../../components/gameItems/Text';
import { COLORS } from '../../components/_settings/colors';

const Index = observer(function Index() {
  const { setAppMode, textInfoPage } = appStore || {};
  return (
    <Animation inherit>
      <MenuContainer>
        {textInfoPage && (
          <Text fontSize="24px" style={{ marginBottom: '40px' }} color={COLORS.blue1}>
            {textInfoPage}
          </Text>
        )}
        <Button text="Назад в меню" style={{ width: '200px', marginBottom: '20px' }} onClick={() => setAppMode('MENU')} />
      </MenuContainer>
    </Animation>
  );
});

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
