import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classes from './css/backScreen.module.scss';

const BackScreen = observer(function BackScreen({ children }) {
  return <section className={classes.backScreen}>{children}</section>;
});

BackScreen.propTypes = {
  children: PropTypes.node,
};

BackScreen.defaultProps = {
  children: null,
};
export default BackScreen;
