import React from 'react';
import { observer } from 'mobx-react';
import Animation from '../../components/animations/Animation';
import Text from '../../components/gameItems/Text';
import Button from '../../components/buttons/Button';
import { COLORS } from '../../components/_settings/colors';
import ScoreContainer from '../../components/containers/ScoreContainer';
import { getMyScoreAPI, getTopUsersScoreAPI } from '../../common/providers/gameApi';
import Box from '../../components/containers/Box';
import UserLevelStat from '../../common/entity/UserLevelStat';
import ScrollBox from '../../components/containers/ScrollBox';
import { appStore } from '../../common/stores/AppStore';
import { notify } from '../../components/notify/notitfication';
import useTelegram from '../../common/hooks/UseTelegram';

const TotalScore = observer(function TotalScore() {
  const { setAppMode } = appStore || {};
  const { user } = useTelegram() || {};
  const [users, setUsers] = React.useState([]);
  const [myScore, setMyScore] = React.useState(null);

  React.useEffect(() => {
    getTopUsersScoreAPI()
      .then((response) => {
        const topUsers = (response ?? []).map((_m) => new UserLevelStat(_m));
        setUsers(topUsers);
        if (user?.id) {
          if (!topUsers.find((_f) => _f?.id === +(user?.id ?? 0))) {
            getMyScoreAPI(user?.id)
              .then((resp) => {
                if (resp) {
                  setMyScore(new UserLevelStat(resp));
                }
              })
              .catch(() => notify({ text: 'Ошибка', type: 'error' }));
          }
        }
      })
      .catch(() => notify({ text: 'Ошибка', type: 'error' }));
  }, []);

  return (
    <Animation inherit>
      <ScoreContainer
        button={<Button text="В меню" style={{ width: '100%', maxWidth: '500px' }} onClick={() => setAppMode('MENU')} />}
        title={
          <Text style={{ marginBottom: '12px' }} fontSize="24px" fontWeight={600} color={COLORS.green1}>
            Топ-10 игроков
          </Text>
        }
      >
        <Box
          style={{
            width: '100%',
            maxWidth: '400px',
            height: '100%',
            margin: 'auto',
          }}
          direction="column"
        >
          <ScrollBox p="20px 0 20px 0">
            {users.map((_m) => (
              <Box style={{ width: '100%' }} spacing="0 12px 0 0" key={_m?.username}>
                <Box
                  style={{
                    width: '40px',
                    flexGrow: 0,
                    flexShrink: 0,
                  }}
                >
                  <Text fontSize="18px" color={user?.id === _m?.id ? COLORS.red1 : COLORS.blue1}>
                    {_m?.position}
                  </Text>
                </Box>
                <Box
                  style={{
                    width: '60%',
                    flexGrow: 0,
                    flexShrink: 0,
                  }}
                >
                  <Text
                    fontSize="18px"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    color={user?.id === _m?.id ? COLORS.red1 : COLORS.blue1}
                  >
                    {_m?.username}
                  </Text>
                </Box>
                <Box
                  style={{
                    width: '30%',
                    flexGrow: 0,
                    flexShrink: 0,
                  }}
                >
                  <Text fontSize="18px" color={user?.id === _m?.id ? COLORS.red1 : COLORS.blue1}>
                    {_m?.levelScore}
                  </Text>
                </Box>
              </Box>
            ))}
            {myScore && (
              <>
                <Text fontSize="18px" color={COLORS.blue1}>
                  .........
                </Text>
                <Box style={{ width: '100%' }} spacing="0 12px 0 0">
                  <Box
                    style={{
                      width: '40px',
                      flexGrow: 0,
                      flexShrink: 0,
                    }}
                  >
                    <Text fontSize="18px" color={COLORS.red1}>
                      {myScore?.position}
                    </Text>
                  </Box>
                  <Box
                    style={{
                      width: '60%',
                      flexGrow: 0,
                      flexShrink: 0,
                    }}
                  >
                    <Text fontSize="18px" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} color={COLORS.red1}>
                      {myScore?.username}
                    </Text>
                  </Box>
                  <Box
                    style={{
                      width: '30%',
                      flexGrow: 0,
                      flexShrink: 0,
                    }}
                  >
                    <Text fontSize="18px" color={COLORS.red1}>
                      {myScore?.levelScore}
                    </Text>
                  </Box>
                </Box>
              </>
            )}
          </ScrollBox>
        </Box>
      </ScoreContainer>
    </Animation>
  );
});

export default TotalScore;
