export const defaultNewObject = {
  modify: false,
  coordinateX: 0,
  coordinateY: 0,
  width: 0,
  height: 0,
};
export const defaultZoomData = { d: 0, cX: 0, cY: 0, zoom: 1 };

export const defaultMoveData = { move: false, startX: 0, startY: 0, translateX: 0, translateY: 0 };
