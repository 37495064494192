import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classes from './css/Select.module.scss';
import { domEvents } from '../../common/utils/domUtils';

let searchInterval;

const Select = observer(function Select({
  readOnly,
  itemsLengthViews,
  items,
  dataProvider,
  field,
  defaultValue,
  multiply,
  disabled,
  label,
  style,
  placeHolder,
  w,
  dropDownMinWidth,
  dropViewCount,
  onChange,
  search,
  discarded,
  searchRowStay,
  clearButton,
}) {
  const selectRef = React.useRef(null);
  const dropDownRef = React.useRef(null);
  const [state, setState] = React.useState({
    searchItems: [],
  });
  const [dropDown, setDropDown] = React.useState({ show: false, style: null });
  const [value, setValue] = React.useState([]);
  const [valueSearch, setValueSearch] = React.useState(null);
  const [loadingSearch, setLoadingSearch] = React.useState(false);

  React.useEffect(() => {
    setState((prevState) => {
      const itemArray = [...items];
      if (prevState.search) {
        return { ...prevState, items: itemArray, searchItems: items };
      }
      return { ...prevState, items: itemArray };
    });
  }, [JSON.stringify(items)]);

  React.useEffect(() => {
    setState((prevState) => {
      return { ...prevState, disabled, readOnly };
    });
  }, [disabled, readOnly]);

  React.useEffect(() => {
    changeDefaultValue();
  }, [defaultValue, items, multiply]);

  React.useEffect(() => {
    let wrapper = document.getElementById('select_wrapper_list');
    if (!wrapper) {
      wrapper = domEvents.wrapperCreate('select_wrapper_list', 'wrapperFields');
    }
    if (dropDown.show) {
      wrapper.appendChild(dropDownRef.current);
      wrapper.classList.add('show');
      setDropDown(() => {
        return { show: true, style: checkStyle };
      });
    } else if (!dropDown.show && dropDownRef.current) {
      wrapper.classList.remove('show');
      wrapper.innerHTML = '';
      wrapper.remove();
    }
    return () => {
      try {
        wrapper.remove();
      } catch {
        console.log();
      }
    };
  }, [dropDown.show]);

  const onWheel = () => {
    if (search) {
      if (dropDown.show) {
        changeDefaultValue();
        if (multiply) {
          onChange({ value: value.map((_) => _.value), field, object: value });
        }
      } else {
        setValue([]);
      }
      setState((prevState) => {
        return searchRowStay
          ? { ...prevState, searchItems: prevState.searchItems }
          : {
              ...prevState,
              searchItems: prevState.items,
            };
      });
    } else if (multiply && dropDown.show) {
      onChange({ value: value.map((_) => _.value), field, object: value });
    }
    setDropDown(() => {
      return { show: false, style: null };
    });
  };

  const toggleDropdown = () => {
    if (state?.readOnly) return;
    if (search) {
      let itemsArray;
      if (searchRowStay) {
        if (state?.searchItems.length !== 0 && valueSearch) {
          setDropDown((prevState) => {
            return { show: true, style: prevState?.style };
          });
        }
      } else if (items.length !== 0 && (!itemsLengthViews || items.length <= itemsLengthViews)) {
        setDropDown((prevState) => {
          return { show: true, style: prevState?.style };
        });
      }
      setState((prevState) => {
        return searchRowStay
          ? { ...prevState, searchItems: prevState.searchItems }
          : {
              ...prevState,
              searchItems: itemsArray ?? prevState.items,
            };
      });
    } else {
      setDropDown((prevState) => {
        return { show: !prevState.show, style: prevState?.style };
      });
      if (multiply && dropDown.show) {
        onChange({ value: value.map((_) => _.value), field, object: value });
      }
    }
  };

  const changeDefaultValue = () => {
    if (defaultValue === undefined || items.length === 0) {
      setValue([]); // TODO fix if error remove
      return;
    }
    if (multiply) {
      if (!Array.isArray(defaultValue)) return;
      setValue(items.filter((_f) => defaultValue.includes(_f.value)));
    } else {
      if (Array.isArray(defaultValue)) return;
      const val = items.find((_f) => _f.value === defaultValue);
      if (!val) return;
      setValueSearch(() => val?.text);
      setValue([val]);
    }
  };

  const clickRow = React.useCallback(
    (e, obj) => {
      e.stopPropagation();
      e.preventDefault();
      let currentValue = [];
      if (multiply) {
        if (value.find((_f) => _f.value === obj.value)) {
          currentValue = [...value.filter((_f) => _f.value !== obj.value)];
        } else {
          currentValue = [...value, obj];
        }
        setValue(currentValue);
      } else {
        if (discarded) setValue([]);
        else if (!searchRowStay) setValue([obj]);
        onChange({ value: obj.value, field, object: obj });
        setDropDown(() => {
          return { show: false, style: null };
        });
      }
    },
    [state, value, onChange],
  );

  const onInputChange = (e) => {
    if (state?.readOnly || !search) return;
    const val = (e.target.value ?? '').toLowerCase();
    // TODO Fix there
    if (state?.searchRowStay) {
      setValueSearch({ value: e.target.value, text: e.target.value });
    } else {
      setValue(() => {
        return [{ value: e.target.value, text: e.target.value }];
      });
    }
    if (val.trim().length === 0) {
      clearInterval(searchInterval);
      setLoadingSearch(false);
      if (itemsLengthViews && itemsLengthViews < state?.items.length) {
        setDropDown(() => {
          return { show: false, style: null };
        });
      }
      if (state?.searchRowStay) {
        setValueSearch(null);
      } else {
        setValue(() => {
          return [];
        });
      }
      setState((prevState) => {
        return { ...prevState, searchItems: prevState.items };
      });
      if (!searchRowStay) {
        onChange({ value: '', field, object: [] });
      }
    } else if (dataProvider) {
      const loadingItems = async () => {
        const itemsResponse = await dataProvider(val);
        return itemsResponse ?? [];
      };
      setLoadingSearch(() => true);
      clearInterval(searchInterval);
      searchInterval = setTimeout(() => {
        loadingItems().then((r) => {
          setState((prevState) => {
            return { ...prevState, searchItems: r };
          });
          setLoadingSearch(() => false);
          setDropDown((prevState) => {
            return { show: true, style: prevState.style };
          });
        });
      }, 1000);
    } else {
      const timeInterval = items.length > 1000 ? 1000 : 500;
      setLoadingSearch(true);
      clearInterval(searchInterval);
      searchInterval = setTimeout(() => {
        setState((prevState) => {
          const itemsStart = [];
          const itemsIncludes = [];
          const itemsAltText = [];
          const words = val.split(' ');
          prevState.items.forEach((v) => {
            let existStart = true;
            let existInclude = true;
            words.forEach((_v) => {
              if (!v.text.toLowerCase().startsWith(_v.toLowerCase().trim())) {
                existStart = false;
              }
              if (!v.text.toLowerCase().includes(_v.toLowerCase().trim())) {
                existInclude = false;
              }
            });
            if (existStart) {
              itemsStart.push(v);
            } else if (existInclude) {
              itemsIncludes.push(v);
            }
          });
          const itemsTemp = [...itemsStart, ...itemsIncludes, ...itemsAltText];
          return { ...prevState, searchItems: itemsTemp };
        });
        setLoadingSearch(false);
        setDropDown((prevState) => {
          return { show: true, style: prevState.style };
        });
      }, timeInterval);
    }
  };

  const onClickClear = (e) => {
    if (state?.readOnly) return;
    domEvents.breakDefEvent(e);
    if (state?.searchRowStay) {
      setValueSearch(null);
    } else {
      setValue(() => {
        return [];
      });
    }
    setState((prevState) => {
      return { ...prevState, searchItems: prevState.items };
    });
    onChange({ value: '', field, object: null });
  };

  const checkStyle = React.useMemo(() => {
    if (!selectRef.current) return null;
    const coordinates = selectRef.current.getBoundingClientRect();
    const reference = dropViewCount ? dropViewCount * 48 : 500;
    const difference = (search ? state.searchItems : items).length * 48 > reference ? reference : (search ? state.searchItems : items).length * 48;

    let positionY = { top: `${coordinates.y + 50 + 4}px` };
    if (window.innerHeight < difference + coordinates.y + 30) {
      positionY.bottom = '20px';
    }
    if (window.innerHeight - (coordinates.top + 50 + difference) < 20) {
      positionY = { bottom: window.innerHeight - coordinates.top + 4 };
      if (difference - coordinates.top > -20) {
        positionY.top = '20px';
      }
    }
    return { ...positionY, left: `${coordinates.x}px`, width: `${coordinates.width}px` };
  }, [selectRef, valueSearch, dropDown.show, JSON.stringify(state?.items), JSON.stringify(state?.searchItems)]);

  return (
    <div
      className={cx([classes.select, disabled && classes.disabled, search && classes.withSearch])}
      style={{ ...style, width: w, pointerEvents: state?.readOnly ? 'none' : null }}
    >
      <input
        placeholder={placeHolder}
        readOnly={!search}
        onClick={toggleDropdown}
        onChange={onInputChange}
        ref={selectRef}
        value={searchRowStay ? valueSearch?.text ?? '' : value.map((v) => v?.text).join(', ')}
      />
      {label && (
        <label className={(searchRowStay ? valueSearch?.text ?? '' : value.map((v) => v?.text).join(', ')) ? classes.top : null}>{label}</label>
      )}
      <div className={classes.iconWrapper}>
        {search && loadingSearch && (
          <div className={cx([classes.icon, classes.spinner])}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        )}
        {clearButton && value.length !== 0 && loadingSearch && (
          <div className={classes.clear} onClick={onClickClear} role="button" aria-label="очистить" />
        )}
        {/* {!loadingSearch && ( */}
        {/*   <div className={cx([classes.icon, classes.arrow, classes[dropDown.show ? 'active' : 'collapsed']])}> */}
        {/*     <IconSVG name={ICONS_PACK.up2} /> */}
        {/*   </div> */}
        {/* )} */}
      </div>
      <div
        className={classes.dropWrapper}
        onClick={onWheel}
        onWheel={onWheel}
        ref={dropDownRef}
        style={{ display: dropDown.show ? null : 'none' }}
        aria-hidden="true"
      >
        {dropDown.show && dropDown.style && !loadingSearch && (
          <ul
            style={{
              ...dropDown.style,
              minWidth: dropDownMinWidth,
              maxHeight: state?.dropViewCount ? `${dropViewCount * 48}px` : null,
            }}
            className={cx([(search ? state?.searchItems : items)?.length > 0 && classes.list])}
            onWheel={(e) => e.stopPropagation()}
          >
            {(search ? state?.searchItems ?? [] : items ?? []).map((_) => {
              const listStyles = [classes.checkbox];
              if (multiply && value.find((_f) => _f.value === _.value)) {
                listStyles.push(classes.active);
              }
              return (
                <li
                  className={cx([_?.disabled && classes.disabled])}
                  value={_.value}
                  onClick={(e) => (_?.disabled ? null : clickRow(e, _))}
                  key={_.value}
                >
                  {multiply && <div className={cx(listStyles)} />}
                  <div className={classes.content}>{_.node || _.text}</div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
});

Select.propTypes = {
  size: PropTypes.oneOf(['L', 'M', 'S']),
  itemsLengthViews: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.objectOf(Object)),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array, PropTypes.bool]),
  field: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  discarded: PropTypes.bool,
  multiply: PropTypes.bool,
  clearButton: PropTypes.bool,
  w: PropTypes.string,
  dropDownMinWidth: PropTypes.string,
  dropViewCount: PropTypes.number,
  style: PropTypes.objectOf(Object),
  onChange: PropTypes.func,
  dataProvider: PropTypes.func,
  search: PropTypes.bool,
  placeHolder: PropTypes.string,
  searchRowStay: PropTypes.bool,
};

Select.defaultProps = {
  placeHolder: '',
  size: 'M',
  items: [],
  label: '',
  itemsLengthViews: 1000,
  w: '',
  dropDownMinWidth: '200px',
  onChange: Function.prototype,
  defaultValue: null,
  field: '',
  disabled: false,
  readOnly: false,
  discarded: false,
  multiply: false,
  clearButton: false,
  dropViewCount: 0,
  style: null,
  dataProvider: null,
  search: false,
  searchRowStay: false,
};

export default Select;
