import React from 'react';
import PropTypes from 'prop-types';
import classes from './css/findObject.module.scss';

function FindObject({ coordinateX, levelID, coordinateY, width, height, id, img, onClick, onDelete }) {
  const [style, setStyle] = React.useState({});
  React.useEffect(() => {
    setStyle({ left: coordinateX, top: coordinateY, width: `${width}px`, height: `${height}px` });
  }, [coordinateX, coordinateY, width, height]);

  const onClickLocal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) onClick({ coordinateX, coordinateY, id, width, height });
  };

  const onDeleteLocal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onDelete) onDelete({ id });
  };

  return (
    <div className={classes.findObject} style={style} id={id} aria-hidden="true">
      <div className={classes.btnDelete} onClick={onDeleteLocal} role="button" aria-label="delete" />
      {!img && <div className={classes.btnAdd} onClick={onClickLocal} role="button" aria-label="add" />}
      {img && <img src={`assets/level_${levelID}/items/${img}`} alt={id} onClick={onClickLocal} />}
    </div>
  );
}

FindObject.propTypes = {
  id: PropTypes.number,
  coordinateX: PropTypes.number,
  coordinateY: PropTypes.number,
  img: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  levelID: PropTypes.number,
};

FindObject.defaultProps = {
  id: 0,
  coordinateX: 0,
  coordinateY: 0,
  width: 0,
  height: 0,
  onClick: null,
  onDelete: null,
  levelID: null,
  img: null,
};

export default FindObject;
