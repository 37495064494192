import './css/clickEffect.scss';

export function clickEffect({ clientX, clientY, zoom, coordinateY, coordinateX, coefficientX, coefficientY, width, levelID, img, height, id }) {
  const root = document.querySelector(`#${id}`);
  const box = document.createElement('DIV');
  const click = document.createElement('DIV');

  click.classList.add('click-effect');
  click.style.top = `${(clientY - 10) / zoom}px`;
  click.style.left = `${(clientX - 10) / zoom}px`;

  box.classList.add('click-image');
  box.style.top = `${coordinateY / coefficientY}px`;
  box.style.left = `${coordinateX / coefficientY}px`;
  box.style.width = `${width / coefficientX}px`;
  box.style.height = `${height / coefficientY}px`;
  box.style.backgroundImage = `url(assets/level_${levelID}/items/${img})`;

  if (process.env.REACT_APP_MARK_FOUND_OBJECT === 'true') {
    const check = document.createElement('DIV');
    check.classList.add('check-image');
    check.style.top = `${coordinateY / coefficientY}px`;
    check.style.left = `${coordinateX / coefficientY}px`;
    check.style.width = `${width / coefficientX}px`;
    check.style.height = `${height / coefficientY}px`;
    check.dataset.x = [coordinateX, coordinateY, width, height].join(',');
    root.appendChild(check);
  }

  root.appendChild(click);
  root.appendChild(box);

  setTimeout(() => {
    box.remove();
    click.remove();
  }, 1000);
}

export const reCalcCheckPosition = ({ refContainer, level }) => {
  if (process.env.REACT_APP_MARK_FOUND_OBJECT === 'true') {
    const checkItems = document.querySelectorAll('.check-image');
    if (checkItems.length) {
      const { width, height } = level || {};
      const currentWidth = refContainer.current.offsetWidth;
      const currentHeight = refContainer.current.offsetHeight;
      const coefficientX = width / currentWidth;
      const coefficientY = height / currentHeight;

      checkItems.forEach((v) => {
        const xData = (v?.dataset?.x ?? '').split(',');
        if (xData.length) {
          v.style.left = `${+xData[0] / coefficientY}px`;
          v.style.top = `${+xData[1] / coefficientY}px`;
          v.style.width = `${+xData[2] / coefficientX}px`;
          v.style.height = `${+xData[3] / coefficientY}px`;
        }
      });
    }
  }
};
