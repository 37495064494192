import PropTypes from 'prop-types';

class UserLevelStat {
  constructor(inDto) {
    this.levelAttempts = inDto?.levelAttempts;
    this.date = inDto?.date;
    this.id = +(inDto?.id ?? 0);
    this.levelData = inDto?.levelData;
    this.levelId = inDto?.levelId;
    this.levelScore = inDto?.levelScore;
    this.levelComplete = inDto?.levelComplete;
    this.username = inDto?.username;
    this.position = inDto?.position;
  }
}

UserLevelStat.propTypes = {
  levelAttempts: PropTypes.number,
  date: PropTypes.string,
  id: PropTypes.number,
  levelData: PropTypes.string,
  levelComplete: PropTypes.bool,
  levelId: PropTypes.number,
  levelScore: PropTypes.number,
  username: PropTypes.string,
  position: PropTypes.number,
};
export default UserLevelStat;
