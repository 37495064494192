import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classes from './css/scoreText.module.scss';

const Text = observer(function Text({ children, style, fontSize, fontWeight, color }) {
  return (
    <p className={classes.text} style={{ ...style, fontSize, fontWeight, color }}>
      {children}
    </p>
  );
});

Text.propTypes = {
  children: PropTypes.node,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.objectOf(Object),
};
Text.defaultProps = {
  children: null,
  fontSize: null,
  fontWeight: null,
  color: null,
  style: null,
};

export default Text;
