import PropTypes from 'prop-types';
import LevelClass from './LevelClass';

class LevelDataClass {
  constructor(inDto) {
    this.id = inDto?.id;
    this.name = inDto?.name;
    this.data = inDto?.data ? new LevelClass(inDto.data) : null;
  }
}

LevelDataClass.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  data: PropTypes.instanceOf(LevelClass),
};
export default LevelDataClass;
