import PropTypes from 'prop-types';

class GameObjects {
  constructor(inDto) {
    this.id = inDto?.id;
    this.coordinateX = inDto?.coordinateX;
    this.coordinateY = inDto?.coordinateY;
    this.height = inDto?.height;
    this.width = inDto?.width;
    this.img = inDto?.img;
  }
}

GameObjects.propTypes = {
  id: PropTypes.number,
  coordinateX: PropTypes.number,
  coordinateY: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  img: PropTypes.string,
};
export default GameObjects;
