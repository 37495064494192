import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classes from './css/scoreContainer.module.scss';

const ScoreContainer = observer(function ScoreContainer({ children, button, title }) {
  return (
    <article className={classes.container}>
      <div className={classes.title}>{title}</div>
      <div className={classes.contentWrapper}>
        <div className={classes.content}>{children}</div>
      </div>
      <div className={classes.button}>{button}</div>
    </article>
  );
});

ScoreContainer.propTypes = {
  children: PropTypes.node,
  button: PropTypes.node,
  title: PropTypes.node,
};

ScoreContainer.defaultProps = {
  children: null,
  title: null,
  button: null,
};
export default ScoreContainer;
