import lodashMethods from '../../../common/utils/lodashUtils';

export const eventMoveScreen = ({ e, refContainer, refScreen, moveData, setMoveData, zoomData }) => {
  let x;
  let y;

  const wImage = refContainer?.current?.offsetWidth;
  const hImage = refContainer?.current?.offsetHeight;
  const wScreen = refScreen?.current?.offsetWidth;
  const hScreen = refScreen?.current?.offsetHeight;

  if (e?.touches?.length > 0) {
    x = e.touches[0].clientX;
    y = e.touches[0].clientY;
  } else {
    x = e.clientX;
    y = e.clientY;
  }
  let translateX = moveData.translateX + (moveData.startX - x);
  let translateY = moveData.translateY + (moveData.startY - y);
  const offsetX = (zoomData.cX * (zoomData.zoom - 1)) / zoomData.zoom; // X смещение положения контейнейра от координат точек трансформации и зума
  const offsetY = (zoomData.cY * (zoomData.zoom - 1)) / zoomData.zoom; // Y смещение положения контейнейра от координат точек трансформации и зума

  if (translateX < -offsetX) {
    translateX = -offsetX;
  } else if ((wImage * zoomData.zoom - wScreen) / zoomData.zoom - offsetX < translateX) {
    translateX = moveData.translateX;
  }

  if (translateY < -offsetY) {
    translateY = -offsetY;
  } else if ((hImage * zoomData.zoom - hScreen) / zoomData.zoom - offsetY < translateY) {
    translateY = moveData.translateY;
  }

  refContainer.current.style.transformOrigin = `${zoomData.cX}px ${zoomData.cY}px`;
  refContainer.current.style.transform = `scale(${zoomData.zoom}) translate(${-translateX}px,${-translateY}px)`;
  setMoveData((prevState) => {
    return { ...prevState, startX: x, startY: y, translateY, translateX };
  });
};

const checkPosition = ({ refContainer, refScreen, moveData, zoomData }) => {
  const cloneModeData = lodashMethods.cloneDeep(moveData);
  const wImage = refContainer?.current?.offsetWidth;
  const hImage = refContainer?.current?.offsetHeight;
  const wScreen = refScreen?.current?.offsetWidth;
  const hScreen = refScreen?.current?.offsetHeight;

  const offsetX = (zoomData.cX * (zoomData.zoom - 1)) / zoomData.zoom; // X смещение положения контейнейра от координат точек трансформации и зума
  const offsetY = (zoomData.cY * (zoomData.zoom - 1)) / zoomData.zoom; // Y смещение положения контейнейра от координат точек трансформации и зума

  if (cloneModeData.translateX < -offsetX) {
    cloneModeData.translateX = -offsetX;
  } else if ((wImage * zoomData.zoom - wScreen) / zoomData.zoom - offsetX < cloneModeData.translateX) {
    cloneModeData.translateX = (wImage * zoomData.zoom - wScreen) / zoomData.zoom - offsetX;
  }

  if (cloneModeData.translateY < -offsetY) {
    cloneModeData.translateY = -offsetY;
  } else if ((hImage * zoomData.zoom - hScreen) / zoomData.zoom - offsetY < cloneModeData.translateY) {
    cloneModeData.translateY = (hImage * zoomData.zoom - hScreen) / zoomData.zoom - offsetY;
  }
  return cloneModeData;
};

export const resetPosition = ({ refContainer, zoomData, moveData, setZoomData, setMoveData }) => {
  setZoomData(zoomData);
  setMoveData(moveData);
  refContainer.current.style.transformOrigin = `${zoomData.cX}px ${zoomData.cY}px`;
  refContainer.current.style.transform = `scale(${zoomData.zoom}) translate(${-moveData.translateX}px,${-moveData.translateY}px)`;
};

export const zoomEvent = ({ e, zoomData, setZoomData, setMoveData, refContainer, refScreen, moveData }) => {
  const x1 = e.touches[0].clientX;
  const y1 = e.touches[0].clientY;
  const x2 = e.touches[1].clientX;
  const y2 = e.touches[1].clientY;
  const cX = (x1 + x2) / 2;
  const cY = (y1 + y2) / 2;

  const d = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
  if (!zoomData.d) {
    setZoomData((prevState) => {
      const cloneZoomData = lodashMethods.cloneDeep(prevState);
      cloneZoomData.d = d;
      cloneZoomData.cX = cX;
      cloneZoomData.cY = cY;
      return cloneZoomData;
    });
  } else {
    const cloneZoomData = lodashMethods.cloneDeep(zoomData);
    if (cloneZoomData.d < d) {
      if (cloneZoomData.zoom < 2) {
        cloneZoomData.zoom += 0.05;
      } else cloneZoomData.zoom = 2;
    } else if (cloneZoomData.d > d) {
      if (cloneZoomData.zoom > 1) {
        cloneZoomData.zoom -= 0.05;
      } else cloneZoomData.zoom = 1;
    }

    cloneZoomData.d = d;
    cloneZoomData.cX = cX;
    cloneZoomData.cY = cY;

    const cloneMoveData = checkPosition({ refContainer, refScreen, moveData, zoomData: cloneZoomData });

    setZoomData(cloneZoomData);
    setMoveData(cloneMoveData);
    refContainer.current.style.transformOrigin = `${cloneZoomData.cX}px ${cloneZoomData.cY}px`;
    refContainer.current.style.transform = `scale(${cloneZoomData.zoom}) translate(${-cloneMoveData.translateX}px,${-cloneMoveData.translateY}px)`;
  }
};
