import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classes from './css/ScrollBox.module.scss';

const ScrollBox = observer(function ScrollBox({ children, p, m, style }) {
  return (
    <section className={classes.scrollBox} style={{ ...style, margin: m, padding: p }}>
      {children}
    </section>
  );
});

ScrollBox.propTypes = {
  m: PropTypes.objectOf(Object),
  p: PropTypes.objectOf(Object),
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(Object),
};

ScrollBox.defaultProps = {
  m: null,
  p: null,
  style: null,
};
export default ScrollBox;
