import React, { Suspense } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Editor from './editor';
import Game from './game';
import Menu from './menu';
import { appStore } from '../common/stores/AppStore';
import CurrentScore from './scores/CurrentScore';
import Help from './help';
import useOrientationHook from '../common/hooks/UseOrientationHook';
import BackScreen from '../components/containers/BackScreen';
import useTelegram from '../common/hooks/UseTelegram';
import TotalScore from './scores/TotalScore';
import Info from './info';
import Nouserpage from './nouserpage';

const Index = observer(function Index() {
  const { mode, setOrientation } = appStore || {};
  const { orientation } = useOrientationHook();
  const { TELEGRAM } = useTelegram();

  React.useEffect(() => {
    // function expand() {
    //   if (!this.isExpanded) {
    //     this.expand();
    //   }
    // }

    TELEGRAM.ready();
    TELEGRAM.expand();
    // TELEGRAM.onEvent('viewportChanged', expand);
  }, []);

  React.useEffect(() => {
    setOrientation(orientation);
  }, [orientation]);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Suspense>
            <BackScreen>
              {mode === 'MENU' && <Menu />}
              {mode === 'GAME' && <Game />}
              {mode === 'HELP' && <Help />}
              {mode === 'CURRENT_SCORE' && <CurrentScore />}
              {mode === 'TOP_SCORES' && <TotalScore />}
              {mode === 'INFO' && <Info />}
              {mode === 'NOUSER' && <Nouserpage />}
            </BackScreen>
          </Suspense>
        </Route>
        <Route exact path="/game_editor">
          <Suspense>
            <BackScreen>
              <Editor />
            </BackScreen>
          </Suspense>
        </Route>
      </Switch>
    </Router>
  );
});

export default Index;
