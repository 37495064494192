export const Levels = [
  {
    levelId: 1,
    backImage: 'back_1.jpg',
    gameObjects: [
      { id: 1, img: '1.png' },
      { id: 2, img: '2.png' },
      { id: 3, img: '3.png' },
      { id: 4, img: '4.png' },
      { id: 5, img: '5.png' },
      { id: 6, img: '6.png' },
      { id: 7, img: '7.png' },
      { id: 8, img: '8.png' },
      { id: 9, img: '9.png' },
      { id: 10, img: '10.png' },
      { id: 11, img: '11.png' },
      { id: 12, img: '12.png' },
      { id: 13, img: '13.png' },
      { id: 14, img: '14.png' },
      { id: 15, img: '15.png' },
      { id: 16, img: '16.png' },
      { id: 17, img: '17.png' },
      { id: 18, img: '18.png' },
      { id: 19, img: '19.png' },
      { id: 20, img: '20.png' },
      { id: 21, img: '21.png' },
      { id: 22, img: '22.png' },
      { id: 23, img: '23.png' },
      { id: 24, img: '24.png' },
      { id: 25, img: '25.png' },
      { id: 26, img: '26.png' },
      { id: 27, img: '27.png' },
      { id: 28, img: '28.png' },
      { id: 29, img: '29.png' },
      { id: 30, img: '30.png' },
      { id: 31, img: '31.png' },
      { id: 32, img: '32.png' },
      { id: 33, img: '33.png' },
      { id: 34, img: '34.png' },
      { id: 35, img: '35.png' },
      { id: 36, img: '36.png' },
      { id: 37, img: '37.png' },
      { id: 38, img: '38.png' },
      { id: 39, img: '39.png' },
      { id: 40, img: '40.png' },
      { id: 41, img: '41.png' },
      { id: 42, img: '42.png' },
      { id: 43, img: '43.png' },
      { id: 44, img: '44.png' },
      { id: 45, img: '45.png' },
      { id: 46, img: '46.png' },
      { id: 47, img: '47.png' },
      { id: 48, img: '48.png' },
      { id: 49, img: '49.png' },
      { id: 50, img: '50.png' },
      { id: 51, img: '51.png' },
      { id: 52, img: '52.png' },
      { id: 53, img: '53.png' },
      { id: 54, img: '54.png' },
      { id: 55, img: '55.png' },
      { id: 56, img: '56.png' },
      { id: 57, img: '57.png' },
      { id: 58, img: '58.png' },
      { id: 59, img: '59.png' },
      { id: 60, img: '60.png' },
    ],
  },
  {
    levelId: 2,
    backImage: 'back_2.jpg',
    gameObjects: [
      { id: 1, img: '2_1.png' },
      { id: 2, img: '2_2.png' },
      { id: 3, img: '2_3.png' },
      { id: 4, img: '2_4.png' },
      { id: 5, img: '2_5.png' },
      { id: 6, img: '2_6.png' },
      { id: 7, img: '2_7.png' },
      { id: 8, img: '2_8.png' },
      { id: 9, img: '2_9.png' },
      { id: 10, img: '2_10.png' },
      { id: 11, img: '2_11.png' },
      { id: 12, img: '2_12.png' },
      { id: 13, img: '2_13.png' },
      { id: 14, img: '2_14.png' },
      { id: 15, img: '2_15.png' },
      { id: 16, img: '2_16.png' },
      { id: 17, img: '2_17.png' },
      { id: 18, img: '2_18.png' },
      { id: 19, img: '2_19.png' },
      { id: 20, img: '2_20.png' },
      { id: 21, img: '2_21.png' },
      { id: 22, img: '2_22.png' },
      { id: 23, img: '2_23.png' },
      { id: 24, img: '2_24.png' },
      { id: 25, img: '2_25.png' },
      { id: 26, img: '2_26.png' },
      { id: 27, img: '2_27.png' },
      { id: 28, img: '2_28.png' },
      { id: 29, img: '2_29.png' },
      { id: 30, img: '2_30.png' },
    ],
  },
];
