import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classes from './css/TextField.module.scss';

const TextField = observer(function TextField({
  id,
  placeHolder,
  defaultValue,
  field,
  label,
  style,
  w,
  onClick,
  onChange,
  onBlur,
  onFocus,
  readOnly,
  refProp,
}) {
  const input = React.useRef(null);
  const [value, setValue] = React.useState({ financialGoal: '' });

  React.useEffect(() => {
    setValue({ financialGoal: defaultValue ?? '' });
  }, [defaultValue]);

  const handleChange = React.useCallback(
    (evt) => {
      const financialGoal = evt.target.validity.valid ? evt.target.value : value.financialGoal;
      if (evt.target.value.length === 0) {
        setValue({ financialGoal: evt.target.value });
      } else if (financialGoal === undefined) {
        setValue({ financialGoal: '' });
      } else {
        setValue({ financialGoal });
      }
    },
    [value],
  );

  const response = (e) => {
    return { e, value: e.target.value, field };
  };

  const onChanged = (e) => {
    if (onChange) {
      onChange(response(e));
    }
  };

  const onBlurred = (e) => {
    const val = e.target.value;
    if (val.length === 0) {
      return;
    }
    if (defaultValue === value.financialGoal && !onChange) {
      return;
    }
    if (onBlur) {
      onBlur(response(e));
    }
  };

  const onFocused = (e) => {
    if (onFocus) {
      onFocus(response(e));
    }
  };

  return (
    <div
      className={cx([classes.textField])}
      ref={refProp}
      style={{
        ...style,
        width: w ? '100%' : null,
        maxWidth: w,
        pointerEvents: readOnly ? 'none' : null,
      }}
    >
      <input
        readOnly={readOnly}
        ref={input}
        id={id}
        placeholder={placeHolder}
        onInput={handleChange}
        onClick={(e) => onClick && onClick(response(e))}
        onChange={onChanged}
        onBlur={onBlurred}
        onFocus={onFocused}
        aria-labelledby={label}
        value={value.financialGoal}
        tabIndex="0"
        style={{
          pointerEvents: readOnly ? 'none' : null,
        }}
      />
      {label && <label>{label}</label>}
    </div>
  );
});

TextField.propTypes = {
  id: PropTypes.string,
  stringLength: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  field: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  w: PropTypes.string,
  style: PropTypes.objectOf(Object),
  onKeyEnter: PropTypes.func,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  readOnly: PropTypes.bool,
  placeHolder: PropTypes.string,
};

TextField.defaultProps = {
  placeHolder: '',
  label: '',
  defaultValue: '',
  w: '',
  id: null,
  field: '',
  disabled: false,
  style: null,
  onKeyEnter: null,
  onClick: null,
  onChange: null,
  onBlur: null,
  onFocus: null,
  readOnly: false,
};

export default TextField;
