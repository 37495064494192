import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/modal/Modal';
import { dialogStore } from '../../../../common/stores/DialogStore';
import Button from '../../../../components/buttons/Button';
import { getLevelSettingsByTypeAPI } from '../../../../common/providers/gameApi';
import Box from '../../../../components/containers/Box';
import Select from '../../../../components/fields/Select';
import TextField from '../../../../components/fields/TextField';
import LevelSettings from '../../../../common/entity/LevelSettings';
import lodashMethods from '../../../../common/utils/lodashUtils';
import { notify } from '../../../../components/notify/notitfication';
import { getAllLevelAPI, saveLevelSettingsAPI } from '../../../../common/providers/editorApi';
import { appStore } from '../../../../common/stores/AppStore';

const DialogSettingLevel = observer(function DialogSettingLevel() {
  const { dialogState, setDialogState } = dialogStore || {};
  const { apiKey } = appStore || {};
  const [levelsTemplate, setLevelsTemplate] = React.useState([]);
  const [settings, setSettings] = React.useState(new LevelSettings());

  React.useEffect(() => {
    if (dialogState?.type === 'SETTINGS') {
      getLevelSettingsByTypeAPI('LEVEL').then((response) => {
        setSettings(new LevelSettings(response?.data));
      });
      getAllLevelAPI({ apiKey }).then((response) => {
        setLevelsTemplate((response ?? []).map((_m) => ({ value: +(_m?.id ?? 0), text: _m?.name })));
      });
    }
  }, [dialogState, apiKey]);

  const onChange = ({ field, value }) => {
    setSettings((prevState) => {
      const clone = lodashMethods.cloneDeep(prevState);
      clone[field] = +value;
      return clone;
    });
  };

  const closeDialog = () => {
    setDialogState(null);
    setLevelsTemplate([]);
  };

  const onSaveSettings = () => {
    if (!settings.levelId || !settings.levelAttempts || Number.isNaN(settings.levelAttempts)) {
      notify({ text: 'Неверные параметры', type: 'warning' });
      return;
    }
    saveLevelSettingsAPI({ type: 'LEVEL', data: settings, key: apiKey }).then(() => {
      notify({ text: 'Настройки игры сохранены.', type: 'success' });
    });
  };

  return (
    <Modal title="Настройка игры" show={dialogState?.type === 'SETTINGS'} width="660px" onCancel={closeDialog}>
      <Box direction="column" spacing="0 0 12px 0">
        <Select items={levelsTemplate} label="Выберите уровень" onChange={onChange} field="levelId" defaultValue={settings?.levelId} />
        <TextField label="Укажите число попыток" onChange={onChange} field="levelAttempts" defaultValue={settings?.levelAttempts} />
      </Box>
      <Button text="Сохранить настройки" onClick={onSaveSettings} style={{ marginTop: '20px' }} />
    </Modal>
  );
});

DialogSettingLevel.propTypes = {
  onSelect: PropTypes.func,
};

DialogSettingLevel.defaultProps = {
  onSelect: Function.prototype,
};

export default DialogSettingLevel;
