import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classes from './css/circleTimer.module.scss';

let interval;
const CircleTimer = observer(function CircleTimer({ onEndTime, timeRef, startTimer }) {
  const [number, setNumber] = React.useState(60);

  React.useEffect(() => {
    if (startTimer) {
      interval = setInterval(() => {
        setNumber((prevState) => {
          if (prevState === 0) {
            clearInterval(interval);
            return prevState;
          }
          return prevState - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [startTimer]);

  React.useEffect(() => {
    if (timeRef) timeRef.current = number;
    if (!number) {
      onEndTime();
    }
  }, [number]);

  const color = React.useMemo(() => {
    if (number > 30) return classes.green;
    if (number > 15) return classes.yellow;
    return classes.red;
  }, [number]);

  if (!startTimer) return null;
  return (
    <div className={classes.timer}>
      <div className={cx([classes.number, color])}>{number}</div>
      <svg>
        <circle r="26" cx="30" cy="30" className={color} />
      </svg>
    </div>
  );
});

CircleTimer.propTypes = {
  onEndTime: PropTypes.func,
  getTime: PropTypes.objectOf(Object),
};
CircleTimer.defaultProps = {
  onEndTime: Function.prototype,
  getTime: null,
};

export default CircleTimer;
