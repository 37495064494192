import React from 'react';
import { observer } from 'mobx-react';
import GameMainContainer from '../../components/containers/GameMainContainer';
import BackImage from '../../components/gameItems/BackImage';
import ItemsContainer from '../../components/gameItems/ItemsContainer';
import LevelClass from '../../common/entity/LevelClass';
import { checkUserAPI, getLevelByIdAPI, getLevelSettingsByTypeAPI, getUserByIdAPI, saveUserScoreAPI } from '../../common/providers/gameApi';
import { Levels } from '../../common/entity/Levels';
import CircleTimer from '../../components/gameItems/CircleTimer';
import { appStore } from '../../common/stores/AppStore';
import Animation from '../../components/animations/Animation';
import lodashMethods from '../../common/utils/lodashUtils';
import useTelegram from '../../common/hooks/UseTelegram';
import UserLevelStat from '../../common/entity/UserLevelStat';
import LoadScreen from '../../components/gameItems/LoadScreen';

const Game = observer(function Game() {
  const { setAppMode, setCurrentGameScore, setTextInfoPage } = appStore || {};
  const { user } = useTelegram() || {};
  const timerRef = React.useRef(null);

  const [currentLevel, setCurrentLevel] = React.useState(null);
  const [levelData, setLevelData] = React.useState(null);

  const [timer, setTimer] = React.useState(false);
  const [loadScreen, setLoadScreen] = React.useState({ show: true, title: '' });

  const [userStat, setUserStat] = React.useState(null);
  const [levelRules, setLevelRule] = React.useState(null);

  React.useEffect(() => {
    getLevelSettingsByTypeAPI('LEVEL').then((levelSetting) => {
      if (levelSetting?.data?.levelId) {
        setLevelRule(levelSetting?.data);
        if (user?.username) {
          const request = {
            id: user?.id,
            username: user?.username,
            levelId: levelSetting?.data?.levelId,
          };
          checkUser(levelSetting?.data, request);
        } else if (user?.id) {
          getUserByIdAPI(user.id)
            .then((userdata) => {
              const request = {
                id: user?.id,
                username: userdata?.username,
                levelId: levelSetting?.data?.levelId,
              };
              checkUser(levelSetting?.data, request);
            })
            .catch((e) => {
              console.error('error', e);
              setTextInfoPage('Неизвестный пользователь.');
              setAppMode('INFO');
            });
        } else {
          setTextInfoPage('Неизвестный пользователь.');
          setAppMode('INFO');
        }
      }
    });
  }, []);

  const checkUser = (levelSetting, request) => {
    console.log('!!!!!');
    console.log(user);

    // console.log(user);
    // const request = {
    //   id: 1,
    //   username: 'test',
    //   levelId: levelSetting?.levelId,
    // };
    if (request?.id && request?.username) {
      checkUserAPI(request)
        .then((response) => {
          const stat = new UserLevelStat(response);
          if (stat?.id && stat?.username && !stat.levelComplete) {
            setUserStat(stat);
            setLoadScreen({ show: true, title: `Попытка ${stat.levelAttempts}` });
            loadLevel(levelSetting?.levelId, stat.levelData?.gameObjects);
          } else {
            if (stat.levelComplete) {
              if (stat.levelAttempts === levelSetting.levelAttempts) {
                setTextInfoPage('Попытки закончились.');
              } else {
                setTextInfoPage('Уровнь пройден.');
              }
            }
            if (!stat?.id || !stat?.username) {
              setTextInfoPage('Неизвестный пользователь.');
            }
            setAppMode('INFO');
          }
        })
        .catch(() => console.error('error'));
    } else {
      console.log('NO USER');
      setAppMode('NOUSER');
    }
  };

  const loadLevel = (levelId, userGameObjects) => {
    getLevelByIdAPI(levelId).then((response) => {
      if (response?.data) {
        const level = new LevelClass(response?.data);
        const levelSettings = new LevelClass(Levels.find((_f) => _f?.levelId === level.levelId));
        levelSettings.gameObjects = levelSettings.gameObjects.filter((_f) => level.gameObjects.some((_s) => _s?.id === _f?.id));
        if (userGameObjects && userGameObjects?.length) {
          levelSettings.gameObjects = levelSettings.gameObjects.filter((_f) => userGameObjects.includes(_f?.id));
          level.gameObjects = level.gameObjects.filter((_f) => userGameObjects.includes(_f?.id));
        }
        setCurrentLevel(level);
        setLevelData(levelSettings);
      }
    });
  };

  const onFindObject = (item) => {
    const clone = lodashMethods.cloneDeep(levelData);
    clone.gameObjects = clone.gameObjects.filter((_f) => _f?.id !== item?.id);
    setLevelData(clone);
    if (!clone?.gameObjects?.length) {
      saveUserScore({ gameObjects: [] });
    }
  };

  const onEndTime = () => {
    saveUserScore({ gameObjects: levelData?.gameObjects });
  };

  const saveUserScore = ({ gameObjects }) => {
    const request = {
      id: userStat?.id,
      levelData: { gameObjects: gameObjects.map((_m) => _m?.id) },
      levelScore:
        userStat.levelScore +
        currentLevel.gameObjects.length -
        gameObjects.length +
        timerRef.current +
        (!gameObjects.length ? (levelRules.levelAttempts - userStat.levelAttempts) * 60 : 0),
      levelComplete: levelRules.levelAttempts === userStat.levelAttempts || !gameObjects.length,
    };
    saveUserScoreAPI(request).then(() => {
      setCurrentGameScore({
        foundObjects: currentLevel.gameObjects.length - gameObjects.length,
        timeLeft: timerRef.current,
        attempts: levelRules.levelAttempts - userStat.levelAttempts,
        totalScore: request.levelScore,
        complete: request.levelComplete,
      });
      setTimeout(() => setAppMode('CURRENT_SCORE'), 1000);
    });
  };

  if (!currentLevel) return null;
  return (
    <Animation inherit>
      <GameMainContainer>
        <LoadScreen show={loadScreen?.show} title={loadScreen?.title} />
        <CircleTimer onEndTime={onEndTime} timeRef={timerRef} startTimer={timer} />
        <BackImage
          type="play"
          level={currentLevel}
          onFindObject={onFindObject}
          onStartTimer={(props) => {
            setTimer(props);
            setLoadScreen(null);
          }}
        />
        <ItemsContainer level={levelData} notActive />
      </GameMainContainer>
    </Animation>
  );
});

export default Game;
