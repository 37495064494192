// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuid } from 'uuid';

export const scrollsMethods = {
  scrollToElementWithParams({ id, containerId = 'root', scrollTopDiff = 0 }) {
    const target = document.querySelector(`#${id}`);
    if (target) {
      const elementPosition = target.getBoundingClientRect().top;
      const scrollContainer = document.querySelector(`#${containerId}`);
      const containerPosition = scrollContainer.scrollTop;
      scrollContainer.scrollTo({
        top: Math.abs(containerPosition + elementPosition - scrollTopDiff),
        behavior: 'smooth',
      });
    }
  },
  scrollToElement(id) {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  },
  scrollDocumentTop() {
    window.document.getElementById('root').scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  },
};

export const domEvents = {
  closeEscapeButton({ funcClose }) {
    const fnClose = {};
    const funcDynName = `fn_${uuid().split('-')[0]}`;
    fnClose[`${funcDynName}`] = (e) => {
      if (e.key === 'Escape') {
        document.removeEventListener('keyup', fnClose[`${funcDynName}`]);
        funcClose();
      }
    };
    document.addEventListener('keyup', fnClose[`${funcDynName}`]);
    return fnClose[`${funcDynName}`];
  },
  removeEventByFn({ removeFn }) {
    document.removeEventListener('keyup', removeFn);
  },
  getCoordsElement(elem) {
    if (!elem) return null;
    const box = elem.getBoundingClientRect();
    return {
      top: box.top + window.pageYOffset,
      right: box.right + window.pageXOffset,
      bottom: box.bottom + window.pageYOffset,
      left: box.left + window.pageXOffset,
    };
  },
  wrapperCreate(wrapperId, classWrapper) {
    const wPopup = document.createElement('DIV');
    wPopup.id = wrapperId;
    wPopup.dataset.active = 'false';
    wPopup.setAttribute('role', 'dialogWrapper');
    wPopup.className = classWrapper;
    const root = document.getElementById('root');
    root.appendChild(wPopup);
    return wPopup;
  },
  breakDefEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  },
};

export const keyEventCheck = {
  special: {
    8: true, // backspace
    16: true, // shift
    17: true, // ctrl
    18: true, // alt
    46: true, // delete
    // 'cmd': //
  },
  navigational: {
    37: true, // leftArrow
    38: true, // upArrow
    39: true, // rightArrow
    40: true, // downArrow
  },
  isSpecial(e) {
    return typeof this.special[e.keyCode] !== 'undefined';
  },
  isNavigational(e) {
    return typeof this.navigational[e.keyCode] !== 'undefined';
  },
};
