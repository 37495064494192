const TELEGRAM = window?.Telegram?.WebApp;
const SEARCH = window?.location?.search;

const UseTelegram = function UseTelegram() {
  function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join(''),
    );
  }
  let data = {};
  let user = null;
  if (SEARCH) {
    const params = new URLSearchParams(SEARCH);
    const json = b64DecodeUnicode(params.get('data'));
    console.log(json);
    data = JSON.parse(json);
    user = { id: data.id, username: data.username };
  }
  const onCloseApp = () => {
    if (TELEGRAM) {
      TELEGRAM.close();
    }
    return null;
  };

  return {
    TELEGRAM,
    onCloseApp,
    external: TELEGRAM.initDataUnsafe?.user,
    user: TELEGRAM.initDataUnsafe?.user ? TELEGRAM.initDataUnsafe?.user : user,
  };
};

export default UseTelegram;
