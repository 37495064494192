import { makeAutoObservable } from 'mobx';

class DialogStore {
  dialogState = null;

  constructor() {
    makeAutoObservable(this);
  }

  setDialogState = (props) => {
    this.dialogState = props;
  };
}

export const dialogStore = new DialogStore();
