import { makeAutoObservable } from 'mobx';

class AppStore {
  mode = 'MENU';
  textInfoPage = null;
  currentGameScore = 0;
  orientation = null;
  apiKey = '';

  constructor() {
    makeAutoObservable(this);
  }

  /**
   *
   * @param {'MENU','GAME','TOP','EDITOR','CURRENT_SCORE','TOP_SCORES','INFO','HELP'} props
   */
  setAppMode = (props) => {
    this.mode = props;
  };

  setTextInfoPage = (props) => {
    this.textInfoPage = props;
  };

  setCurrentGameScore = (props) => {
    this.currentGameScore = props;
  };

  setOrientation = (props) => {
    this.orientation = props;
  };

  clearCurrentGameScore = () => {
    this.currentGameScore = 0;
  };

  setApiKey = (props) => {
    this.apiKey = props;
  };
}

export const appStore = new AppStore();
