import lodashMethods from 'common/utils/lodashUtils';
import { observer } from 'mobx-react';
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import classes from './css/Animation.module.scss';

const Animation = observer(function Animation({ children, variant, inherit, delay }) {
  const [styleState, setStyleState] = React.useState({ display: 'none' });
  const ref = React.useRef(null);

  React.useEffect(() => {
    const setDisplay = () => {
      setStyleState((prevState) => {
        const clone = lodashMethods.cloneDeep(prevState);
        clone.display = null;
        return clone;
      });
    };

    if (!delay) setDisplay();
    else {
      ref.current = setTimeout(setDisplay, delay * 1000);
    }
    return () => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
    };
  }, []);

  return (
    <div className={cx([classes.anim, classes[variant], inherit && classes.inherit])} style={styleState}>
      {children}
    </div>
  );
});

Animation.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)]),
  variant: PropTypes.oneOf(['fade', 'expand', 'blink']),
  inherit: PropTypes.bool,
  delay: PropTypes.number,
};

Animation.defaultProps = {
  style: null,
  variant: 'fade',
  inherit: false,
  delay: 0,
};
export default Animation;
