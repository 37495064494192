import PropTypes from 'prop-types';
import GameObjects from './GameObjects';

class LevelClass {
  constructor(inDto) {
    this.levelId = inDto?.levelId;
    this.backImage = inDto?.backImage;
    this.width = inDto?.width;
    this.height = inDto?.height;
    this.gameObjects = (inDto?.gameObjects ?? []).map((_m) => new GameObjects(_m));
  }
}

LevelClass.propTypes = {
  levelId: PropTypes.number,
  width: PropTypes.number,
  backImage: PropTypes.string,
  height: PropTypes.number,
  gameObjects: PropTypes.arrayOf(PropTypes.instanceOf(GameObjects)),
};
export default LevelClass;
