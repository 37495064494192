import ReactDOM from 'react-dom/client';
import './common/styles/style.scss';
import Index from './pages';
import reportWebVitals from './reportWebVitals';
import { unregister } from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Index />,
  // </React.StrictMode>,
);
unregister();
reportWebVitals();
