import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/modal/Modal';
import { dialogStore } from '../../../../common/stores/DialogStore';
import Button from '../../../../components/buttons/Button';
import TextField from '../../../../components/fields/TextField';
import { confirmWindow, notify } from '../../../../components/notify/notitfication';
import { saveLevelAPI, updateLevelAPI } from '../../../../common/providers/editorApi';
import { appStore } from '../../../../common/stores/AppStore';

const DialogSaveLevel = observer(function DialogSaveLevel({ onSelect }) {
  const { dialogState, setDialogState } = dialogStore || {};
  const { apiKey } = appStore || {};
  const [levelName, setLevelName] = React.useState('');

  React.useEffect(() => {
    if (dialogState?.type === 'SAVE') {
      setLevelName(dialogState?.data?.name);
    }
  }, [dialogState, apiKey]);

  const onSaveLevel = () => {
    if (dialogState?.data?.id) {
      confirmWindow({
        text: 'Как сохранить уровень ?',
        buttons: [
          {
            text: 'Перезаписать',
            func: () => {
              updateLevelAPI({
                data: dialogState.levelSettings,
                id: dialogState?.data?.id,
                name: levelName,
                key: apiKey,
              }).then(() => {
                closeDialog();
              });
            },
          },
          {
            text: 'Создать новый',
            func: () => {
              saveLevelAPI({ data: dialogState.levelSettings, name: levelName, key: apiKey })
                .then(() => {
                  closeDialog();
                })
                .catch(() => notify({ text: 'Неверный ключ доступа', type: 'error' }));
            },
          },
          {
            text: 'Отмена',
            color: 'red',
            func: () => {
              console.log('cancel');
            },
          },
        ],
      });
      return;
    }
    saveLevelAPI({ data: dialogState.levelSettings, name: levelName, key: apiKey })
      .then(() => {
        closeDialog();
      })
      .catch(() => notify({ text: 'Неверный ключ доступа', type: 'error' }));
  };

  const onChange = ({ value }) => {
    setLevelName(value);
  };

  const closeDialog = () => {
    onSelect({ level: dialogState?.levelSettings, data: { ...dialogState?.data, name: levelName } });
    setDialogState(null);
  };

  return (
    <Modal
      title="Сохранение"
      show={dialogState?.type === 'SAVE'}
      width="560px"
      onCancel={closeDialog}
      buttons={<Button text="Сохранить" onClick={onSaveLevel} style={{ marginRight: '8px' }} />}
    >
      <TextField defaultValue={levelName} onChange={onChange} label="Название" w="100%" />
    </Modal>
  );
});

DialogSaveLevel.propTypes = {
  onSelect: PropTypes.func,
};

DialogSaveLevel.defaultProps = {
  onSelect: Function.prototype,
};

export default DialogSaveLevel;
