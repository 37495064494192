import { makeAutoObservable } from 'mobx';
import LevelClass from '../entity/LevelClass';

class ObjectsStore {
  levelSettings = null;
  selectedItem = null;
  dimensionLevel = null;
  constructor() {
    makeAutoObservable(this);
  }

  setLevelSettings = (props) => {
    this.levelSettings = new LevelClass(props);
  };

  setSelectedItem = (props) => {
    this.selectedItem = props;
  };
}

export const objectsStore = new ObjectsStore();
