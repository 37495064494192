import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classes from './css/game.module.scss';
import { appStore } from '../../common/stores/AppStore';

const GameMainContainer = observer(function GameMainContainer({ children, buttons }) {
  const { orientation } = appStore || {};
  return (
    <article className={cx([classes.gameContainer, orientation?.landscape ? classes.landscape : classes.portrait])}>
      {children}
      {buttons && <section className={classes.buttonsContainer}>{buttons}</section>}
    </article>
  );
});

GameMainContainer.propTypes = {
  children: PropTypes.node,
  buttons: PropTypes.node,
};

GameMainContainer.defaultProps = {
  children: null,
  buttons: null,
};
export default GameMainContainer;
