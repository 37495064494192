import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import GameMainContainer from '../../components/containers/GameMainContainer';
import BackImage from '../../components/gameItems/BackImage';
import Button from '../../components/buttons/Button';
import ItemsContainer from '../../components/gameItems/ItemsContainer';
import { Levels } from '../../common/entity/Levels';
import { objectsStore } from '../../common/stores/ObjectsStore';
import { appStore } from '../../common/stores/AppStore';
import lodashMethods from '../../common/utils/lodashUtils';
import DialogLoadLevel from './_chunk/dialogs/DialogLoadLevel';
import { dialogStore } from '../../common/stores/DialogStore';
import DialogSaveLevel from './_chunk/dialogs/DialogSaveLevel';
import DialogSettingLevel from './_chunk/dialogs/DialogSettingLevel';
import TextField from '../../components/fields/TextField';

const Editor = observer(function Editor() {
  const history = useHistory();
  const { setDialogState } = dialogStore || {};
  const { levelSettings } = objectsStore || {};
  const { apiKey, setApiKey } = appStore || {};
  const [currentLevel, setCurrentLevel] = React.useState(null);
  const [currentLevelData, setCurrentLevelData] = React.useState(null);

  const onSelectLevel = ({ level, data }) => {
    setCurrentLevel(level);
    setCurrentLevelData(data);
  };

  const onChangeObject = ({ type, id }) => {
    setCurrentLevel((prevState) => {
      const clone = lodashMethods.cloneDeep(prevState);
      if (type === 'ADD') {
        clone.gameObjects = clone.gameObjects.filter((_f) => _f?.id !== id);
      } else {
        const item = (Levels.find((_f) => _f?.id === clone?.id)?.gameObjects ?? []).find((_f) => _f?.id === id);
        if (item) clone.gameObjects.push(item);
      }
      return clone;
    });
  };

  return (
    <GameMainContainer
      buttons={
        <>
          <Button text="На главную" onClick={() => history.push('/')} style={{ marginRight: '8px' }} />
          <Button text="Новый" onClick={() => setDialogState({ type: 'NEW' })} style={{ marginRight: '8px' }} />
          <Button text="Загрузить" onClick={() => setDialogState({ type: 'LOAD' })} style={{ marginRight: '8px' }} />
          <Button
            text="Сохранить"
            onClick={() =>
              setDialogState({
                type: 'SAVE',
                data: currentLevelData,
                levelSettings,
              })
            }
            style={{ marginRight: '8px' }}
          />
          <Button text="Настройка" onClick={() => setDialogState({ type: 'SETTINGS' })} style={{ marginRight: '8px' }} />
          <TextField placeHolder="ApiKey" onChange={({ value }) => setApiKey(value)} defaultValue={apiKey} />
        </>
      }
    >
      <DialogSaveLevel onSelect={onSelectLevel} />
      <DialogSettingLevel />
      <DialogLoadLevel onSelect={onSelectLevel} />
      {currentLevel && (
        <>
          <BackImage type="edit" level={currentLevelData?.data} onChangeNewObject={onChangeObject} />
          <ItemsContainer level={currentLevel} />
        </>
      )}
    </GameMainContainer>
  );
});

export default Editor;
