import { observer } from 'mobx-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuid } from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import lodashMethods from 'common/utils/lodashUtils';
import classes from './css/Modal.module.scss';
import { IconSVG } from '../icons/IconSvg';
import ButtonWrapper from '../buttons/ButtonWrapper';
import Text from '../gameItems/Text';
import { ICONS_PACK } from '../icons/params/iconsPack';
import { COLORS } from '../_settings/colors';
import { domEvents } from '../../common/utils/domUtils';

const Modal = observer(function Modal({ children, show, buttons, title, titleAlign, width, height, style, onCancel, wrapperContainer }) {
  const ref = React.useRef(null);
  const [state, setState] = React.useState({
    show,
    titleAlign,
    wrapperContainer,
    style: { ...style, width, height },
    firstInit: true,
    funClose: null,
  });

  React.useEffect(() => {
    let wrapper = document.getElementById(state.wrapperContainer);
    if (!wrapper) {
      wrapper = domEvents.wrapperCreate(state.wrapperContainer, 'modalWrapper');
    }
    const modals = Array.from(document.querySelectorAll('[role="dialogWrapper"]'));
    const levels = (modals ?? []).map((_m) => +(_m?.dataset.level ?? 0)).sort((a, b) => a - b);
    if (show && ref.current) {
      for (let i = 0; i < modals.length; i += 1) {
        const item = modals[i];
        if (wrapper !== item) item.dataset.active = 'false';
        else {
          item.dataset.active = 'true';
          item.dataset.level = `${levels.pop() + 1}`;
        }
      }
      const funcDynName = `fn_${uuid().split('-')[0]}`;
      const funcClose = {};

      funcClose[`${funcDynName}`] = (e) => {
        if (e.key === 'Escape' && show) {
          document.removeEventListener('keyup', funcClose[`${funcDynName}`]);
          onCancel();
        }
      };

      document.addEventListener('keyup', funcClose[`${funcDynName}`]);
      wrapper.appendChild(ref.current);
      wrapper.classList.remove('fadeOut');
      wrapper.classList.add('show');
      wrapper.classList.add('fadeIn');

      setState((prevState) => {
        const clone = lodashMethods.cloneDeep(prevState);
        clone.show = show;
        clone.firstInit = false;
        clone.funClose = funcClose[`${funcDynName}`];
        return clone;
      });
    } else if (!show && ref.current && !state.firstInit) {
      const level = levels.pop() - 1;

      for (let i = 0; i < modals.length; i += 1) {
        const item = modals[i];
        if (wrapper !== item && item.dataset.level === `${level}`) {
          item.dataset.active = 'true';
        } else if (wrapper === item) {
          item.dataset.active = 'false';
          item.dataset.level = '0';
        } else {
          item.dataset.active = 'false';
        }
      }
      wrapper.classList.remove('fadeIn');
      wrapper.classList.add('fadeOut');
      wrapper.classList.remove('show');
      wrapper.remove();

      setState((prevState) => {
        const clone = lodashMethods.cloneDeep(prevState);
        if (clone.funClose) {
          document.removeEventListener('keyup', prevState.funClose);
        }
        clone.show = show;
        clone.firstInit = false;
        clone.funClose = null;
        return clone;
      });
    }
  }, [show]);

  return (
    <div className={classes.modal} ref={ref} style={{ ...state.style, display: !state.show && 'none' }} data-role="dialog">
      {state.show && (
        <>
          {title && (
            <article className={classes.title}>
              <Text fontSize="18px">{title}</Text>
              <ButtonWrapper onClick={onCancel}>
                <IconSVG size="28" name={ICONS_PACK.closeCircle} fillColor={COLORS.green1} />
              </ButtonWrapper>
            </article>
          )}
          <section className={cx([classes.content])}>
            <div>{children}</div>
          </section>
          {buttons && <div>{buttons}</div>}
        </>
      )}
    </div>
  );
});

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  staticContent: PropTypes.node,
  show: PropTypes.bool,
  buttons: PropTypes.element,
  title: PropTypes.node,
  style: PropTypes.objectOf(Object),
  height: PropTypes.string,
  width: PropTypes.string,
  titleAlign: PropTypes.string,
  onCancel: PropTypes.func,
  wrapperContainer: PropTypes.string,
};

Modal.defaultProps = {
  show: false,
  width: '50%',
  wrapperContainer: 'modalContainerWrapper',
  buttons: null,
  title: null,
  style: null,
  staticContent: null,
  height: '',
  titleAlign: '',
  onCancel: null,
};

export default Modal;
