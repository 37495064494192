import { observer } from 'mobx-react';
import MenuContainer from '../../components/containers/MenuContainer';
import Button from '../../components/buttons/Button';
import { appStore } from '../../common/stores/AppStore';
import Animation from '../../components/animations/Animation';
import UseTelegram from '../../common/hooks/UseTelegram';

const Index = observer(function Index() {
  const { setAppMode } = appStore || {};
  const { onCloseApp, user, external } = UseTelegram();
  console.log(user?.id);
  return (
    <Animation inherit>
      <MenuContainer>
        <Button text="Играть" style={{ width: '200px', marginBottom: '20px' }} onClick={() => setAppMode('GAME')} />
        <Button text="Лучшие игроки" style={{ width: '200px', marginBottom: '20px' }} onClick={() => setAppMode('TOP_SCORES')} />
        <Button text="Правила" style={{ width: '200px', marginBottom: '20px' }} onClick={() => setAppMode('HELP')} />
        {onCloseApp && external && <Button text="Выход" style={{ width: '200px' }} onClick={onCloseApp} />}
      </MenuContainer>
    </Animation>
  );
});

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
