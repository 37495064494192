import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { objectsStore } from '../../common/stores/ObjectsStore';
import { appStore } from '../../common/stores/AppStore';
import classes from './css/itemsContainer.module.scss';
import LevelClass from '../../common/entity/LevelClass';

const ItemsContainer = observer(function ItemsContainer({ level, notActive }) {
  const { orientation } = appStore || {};
  const { setSelectedItem, selectedItem } = objectsStore || {};

  return (
    <section className={cx([classes.container, orientation?.landscape ? classes.landscape : classes.portrait])}>
      {(level?.gameObjects ?? [])
        .sort((_a, _b) => (_a?.id ?? 0) - (_b?.id ?? 0))
        .map((_m) => (
          <img
            className={cx([selectedItem?.id === _m?.id && classes.selected])}
            src={`assets/level_${level?.levelId}/items/${_m?.img}`}
            key={_m?.id}
            alt={_m?.id}
            onClick={notActive ? null : () => setSelectedItem(_m)}
          />
        ))}
    </section>
  );
});

ItemsContainer.propTypes = {
  level: PropTypes.instanceOf(LevelClass),
  notActive: PropTypes.bool,
};

ItemsContainer.defaultProps = {
  level: null,
  notActive: false,
};

export default ItemsContainer;
