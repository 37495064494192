import PropTypes from 'prop-types';
import cx from 'classnames';
import classes from './css/button.module.scss';

function Button({ text, style, variant, onClick }) {
  return (
    <button type="button" className={cx([classes.button, classes?.[variant]])} onClick={onClick} style={style}>
      {text}
    </button>
  );
}

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(Object),
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

Button.defaultProps = {
  onClick: Function.prototype,
  text: '',
  style: null,
  variant: 'primary',
};
export default Button;
