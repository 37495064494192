import axios from 'axios';
import { apiProvider, config, interceptor } from './common';

const API = axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_API,
});

interceptor(API);

export const saveLevelAPI = (request) => {
  return apiProvider(API).request({ method: 'post', endpoint: 'level', request, cfg: config() });
};

export const updateLevelAPI = (request) => {
  return apiProvider(API).request({ method: 'put', endpoint: 'level', request, cfg: config() });
};

export const deleteLevelByIdAPI = ({ id, apiKey }) => {
  return apiProvider(API).request({ method: 'delete', endpoint: `level/${id}?key=${apiKey}`, cfg: config() });
};

export const getAllLevelAPI = ({ apiKey }) => {
  return apiProvider(API).request({ method: 'get', endpoint: `levels?key=${apiKey}`, cfg: config() });
};

export const saveLevelSettingsAPI = (request) => {
  return apiProvider(API).request({ method: 'post', endpoint: 'level_settings', request, cfg: config() });
};
