import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import lodashMethods from '../../common/utils/lodashUtils';
import FindObject from './FindObject';
import ObjectRect from './ObjectRect';
import { objectsStore } from '../../common/stores/ObjectsStore';
import classes from './css/backImage.module.scss';
import { notify } from '../notify/notitfication';
import LevelClass from '../../common/entity/LevelClass';
import { clickEffect, reCalcCheckPosition } from './clickEffect';
import { eventMoveScreen, resetPosition } from './_chunck/events';
import { defaultMoveData, defaultNewObject, defaultZoomData } from './_chunck/defaultValues';
import { appStore } from '../../common/stores/AppStore';

const BackImage = observer(function BackImage({ type, level, onFindObject, onChangeNewObject, onStartTimer }) {
  const { selectedItem, setSelectedItem, setLevelSettings } = objectsStore || {};
  const { orientation } = appStore || {};
  const refContainer = React.useRef(null);
  const refScreen = React.useRef(null);
  const refImage = React.useRef(null);
  const refDimension = React.useRef({ width: 0, height: 0 });
  const [newObject, setNewObject] = React.useState(defaultNewObject);
  const [objects, setObjects] = React.useState([]);
  const [zoomData, setZoomData] = React.useState(defaultZoomData);
  const [moveData, setMoveData] = React.useState(defaultMoveData);

  React.useEffect(() => {
    if (level) {
      refDimension.current = { width: level.width, height: level.height };
      setObjects(level?.gameObjects ?? []);
    } else {
      setObjects([]);
    }
  }, [level]);

  React.useEffect(() => {
    reCalcCheckPosition({ level, refContainer });
    resetPosition({ moveData: defaultMoveData, zoomData: defaultZoomData, setMoveData, setZoomData, refContainer });
    onChangeContainerDimension();
  }, [orientation?.landscape, orientation?.dimensions, level]);

  React.useEffect(() => {
    setLevelSettings({
      ...level,
      gameObjects: objects,
      width: refContainer.current.offsetWidth,
      height: refContainer.current.offsetHeight,
    });
  }, [objects]);

  const onCreateObject = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const cloneObject = lodashMethods.cloneDeep(newObject);
    if (!cloneObject.modify) {
      setNewObject({ modify: true, coordinateX: x, coordinateY: y, width: 0, height: 0 });
    } else {
      setNewObject(defaultNewObject);
      cloneObject.id = new Date().getTime();
      cloneObject.noImage = true;
      setObjects((prevState) => {
        const clone = lodashMethods.cloneDeep(prevState);
        clone.push(cloneObject);
        return clone;
      });
    }
  };

  const onFindObjectClick = React.useCallback(
    (e) => {
      const { width, height } = level || {};
      const currentWidth = refContainer.current.offsetWidth;
      const currentHeight = refContainer.current.offsetHeight;

      const coefficientX = width / currentWidth;
      const coefficientY = height / currentHeight;

      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      let findItem;
      const clone = lodashMethods.cloneDeep(objects);
      clone.forEach((item, index, array) => {
        const itemStartX = (item.coordinateX / coefficientX) * zoomData.zoom;
        const itemStartY = (item.coordinateY / coefficientY) * zoomData.zoom;
        const itemEndX = ((item.coordinateX + item.width) / coefficientX) * zoomData.zoom;
        const itemEndY = ((item.coordinateY + item.height) / coefficientY) * zoomData.zoom;

        if (itemStartX <= x && itemStartY <= y && itemEndX >= x && itemEndY >= y) {
          findItem = item;
          array.splice(index, 1);
          if (findItem) {
            clickEffect({
              ...findItem,
              clientX: x,
              clientY: y,
              zoom: zoomData.zoom,
              coefficientX,
              coefficientY,
              levelID: level?.levelId,
              id: 'gameContainer',
            });
            onFindObject(findItem);
            setObjects(clone);
          }
        }
      });
    },
    [objects, zoomData],
  );

  const onAddImage = ({ id }) => {
    if (!selectedItem) {
      notify({ text: 'Объект не выбран', type: 'error' });
      return;
    }
    let cloneObjects = lodashMethods.cloneDeep(objects);
    const item = cloneObjects.find((_f) => _f?.id === id);
    cloneObjects = cloneObjects.filter((_f) => _f?.id !== id);
    item.id = selectedItem.id;
    item.img = selectedItem.img;
    if (item?.noImage) delete item?.noImage;
    cloneObjects.push(item);
    setObjects(cloneObjects);
    onChangeNewObject({ id: item.id, type: 'ADD' });
    setSelectedItem(null);
  };

  const onDelete = ({ id }) => {
    setObjects((prevState) => {
      let clone = lodashMethods.cloneDeep(prevState);
      clone = clone.filter((_f) => _f?.id !== id);
      return clone;
    });
    onChangeNewObject({ id, type: 'DELETE' });
  };

  const onStartMove = (e) => {
    setMoveData((prevState) => {
      const clone = lodashMethods.cloneDeep(prevState);
      clone.move = true;
      if (e?.touches?.length) {
        clone.startX = e.touches[0].clientX;
        clone.startY = e.touches[0].clientY;
      } else {
        clone.startX = e.clientX;
        clone.startY = e.clientY;
      }
      return clone;
    });
  };

  const onEndMove = () => {
    setMoveData((prevState) => {
      const clone = lodashMethods.cloneDeep(prevState);
      clone.move = false;
      clone.startX = 0;
      clone.startY = 0;
      return clone;
    });
  };

  const onMovePosition = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (moveData.move && (!e?.touches || e.touches?.length > 0)) {
      eventMoveScreen({ e, moveData, setMoveData, refContainer, refScreen, zoomData });
    }
    // else if (e?.touches?.length === 2) {
    //   zoomEvent({ e, moveData, setZoomData, zoomData, refContainer, refScreen, setMoveData });
    // }
    else if (type === 'edit') onSelectObjectRange(e);
  };

  const onSelectObjectRange = (e) => {
    if (newObject.modify) {
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      setNewObject((prevState) => {
        const clone = lodashMethods.cloneDeep(prevState);
        clone.width = x - clone.coordinateX;
        clone.height = y - clone.coordinateY;
        return clone;
      });
    }
  };

  const reCalcObjects = () => {
    const { width, height } = refDimension.current || {};
    const currentWidth = refContainer.current.offsetWidth;
    const currentHeight = refContainer.current.offsetHeight;

    if (type === 'edit' && width && height && currentWidth && currentHeight) {
      const coefficientX = width / currentWidth;
      const coefficientY = height / currentHeight;
      setObjects((prevState) => {
        const clone = lodashMethods.cloneDeep(prevState);
        clone.forEach((v) => {
          v.height /= coefficientY;
          v.width /= coefficientX;
          v.coordinateX /= coefficientX;
          v.coordinateY /= coefficientY;
        });
        return clone;
      });
      refDimension.current = { width: currentWidth, height: currentHeight };
    }
  };

  const onLoadedImage = (e) => {
    let tryCount = 0;
    const interval = setInterval(() => {
      if (e.target?.offsetWidth && e.target?.offsetHeight) {
        refContainer.current.style.width = `${e.target?.offsetWidth}px`;
        clearInterval(interval);
        if (type === 'edit') {
          reCalcObjects();
        }
        setTimeout(() => {
          if (type !== 'edit') {
            if (onStartTimer) onStartTimer(true);
          }
        }, 10000);
      }
      tryCount += 1;
      if (tryCount > 50) {
        clearInterval(interval);
        console.log('error img load');
      }
    }, 200);
  };

  const onChangeContainerDimension = () => {
    if (refContainer?.current && refImage?.current) {
      refContainer.current.style.width = `${refImage?.current?.offsetWidth}px`;
      if (type === 'edit') {
        reCalcObjects();
      }
    }
  };
  return (
    <section className={classes.wrapper} ref={refScreen}>
      <article
        className={classes.backImage}
        onClick={type === 'play' ? onFindObjectClick : null}
        onMouseMove={onMovePosition}
        onMouseDown={onStartMove}
        onMouseUp={onEndMove}
        onTouchStart={onStartMove}
        onTouchEnd={onEndMove}
        onDoubleClick={type === 'edit' ? onCreateObject : null}
        onTouchMoveCapture={onMovePosition}
        ref={refContainer}
        id="gameContainer"
      >
        <img src={`./assets/level_${level?.levelId}/background/${level?.backImage}`} alt="game" onLoad={onLoadedImage} ref={refImage} />
        <ObjectRect {...newObject} />
        {type === 'edit' &&
          objects.map((_m) => <FindObject {..._m} key={_m?.id} onDelete={onDelete} onClick={onAddImage} levelID={level?.levelId} />)}
      </article>
    </section>
  );
});

BackImage.propTypes = {
  type: PropTypes.oneOf(['play', 'edit']).isRequired,
  level: PropTypes.instanceOf(LevelClass),
  onFindObject: PropTypes.func,
  onChangeNewObject: PropTypes.func,
  onStartTimer: PropTypes.func,
};

BackImage.defaultProps = {
  level: null,
  onFindObject: null,
  onChangeNewObject: null,
  onStartTimer: null,
};

export default BackImage;
