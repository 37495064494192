import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { observer } from 'mobx-react';
import classes from './css/button.module.scss';

const ButtonWrapper = observer(function ButtonWrapper({ onClick, children, disabled, style, m }) {
  const [styleState] = React.useState({ margin: m, style });
  return (
    <div className={cx([classes.buttonWrapper, disabled && classes.disabled])} role="button" onClick={disabled ? null : onClick} style={styleState}>
      {children}
    </div>
  );
});
ButtonWrapper.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)]),
  m: PropTypes.string,
};

ButtonWrapper.defaultProps = {
  style: null,
  children: null,
  disabled: false,
  m: '',
  onClick: Function.prototype,
};

export default ButtonWrapper;
