import './css/notify.scss';

const popup = (type, text, id) => {
  const popupNew = document.createElement('DIV');
  if (id) popupNew.id = `notify_${id}`;
  popupNew.classList.add('notify-popup');
  popupNew.classList.add(`type-${type}`);
  const textNew = document.createElement('DIV');
  textNew.classList.add('notify-text');
  textNew.innerText = text;
  popupNew.appendChild(textNew);
  return popupNew;
};

const infoBox = (root) => {
  const box = document.createElement('DIV');
  box.id = 'notify_box';
  box.classList.add('notify-box');
  root.appendChild(box);
  return box;
};

/**
 *
 * @param {('info','warning','error','success')}type
 * @param text
 * @param sec
 * @param id
 */
export function notify({ type, text, sec = 3, id = null }) {
  const root = document.querySelector('#root');
  const notifyForDelete = id ? document.querySelector(`#notify_${id}`) : null;
  const box = document.querySelector('#notify_box') ?? infoBox(root);
  const notifyPopup = popup(type, text, id);

  if (notifyForDelete) {
    notifyForDelete.remove();
  }

  box.appendChild(notifyPopup);
  setTimeout(() => {
    notifyPopup.classList.add('show');
  }, 200);

  setTimeout(() => {
    notifyPopup.classList.remove('show');
  }, sec * 1000 - 500);

  setTimeout(() => {
    try {
      notifyPopup.remove();
    } catch {
      // empty
    }
  }, sec * 1000);
}

/**
 *
 * @param text
 * @param {array} buttons => [{text:'',func:'',color:''}]
 */
export function confirmWindow({ text, buttons = [] }) {
  const root = document.querySelector('#root');
  const box = document.querySelector('#confirm_box') ?? confirmBox(root);

  const confirmPopup = document.createElement('DIV');
  const btnContainer = document.createElement('DIV');
  const textNew = document.createElement('DIV');

  confirmPopup.classList.add('confirmPopup');
  textNew.classList.add('confirmText');
  textNew.innerText = text;
  confirmPopup.appendChild(textNew);
  btnContainer.classList.add('btnContainerModal');

  buttons.forEach((v) => {
    btnContainer.appendChild(btnCreate({ ...v, box }));
  });

  box.appendChild(confirmPopup);
  confirmPopup.appendChild(btnContainer);

  setTimeout(() => {
    box.classList.add('show');
  }, 200);
}

const btnCreate = ({ text, func, color = 'blue', box }) => {
  const btn = document.createElement('DIV');
  btn.classList.add('btn');
  btn.classList.add(color);
  btn.innerText = text;

  btn.addEventListener('click', () => {
    box.classList.remove('show');
    setTimeout(() => {
      try {
        box.remove();
      } catch {
        // empty
      }
    }, 500);
    if (func) func();
  });
  return btn;
};
const confirmBox = (root) => {
  const box = document.createElement('DIV');
  box.id = 'confirm_box';
  box.classList.add('confirmBox');
  root.appendChild(box);
  return box;
};
