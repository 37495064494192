import React from 'react';
import { observer } from 'mobx-react';
import MenuContainer from '../../components/containers/MenuContainer';
import Button from '../../components/buttons/Button';
// import { appStore } from '../../common/stores/AppStore';
import Animation from '../../components/animations/Animation';
import Text from '../../components/gameItems/Text';
import { COLORS } from '../../components/_settings/colors';
import classes from './css/nouser.module.scss';
import useTelegram from '../../common/hooks/UseTelegram';
import { checkUserAPI, getLevelSettingsByTypeAPI } from '../../common/providers/gameApi';
import { appStore } from '../../common/stores/AppStore';

const Index = observer(function Index() {
  // const { setAppMode } = appStore || {};
  const [username, setUsername] = React.useState('');
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const { user } = useTelegram() || {};
  const { setAppMode } = appStore || {};

  const usernameOnChange = (e) => {
    setUsername(e.target.value);
  };

  const startGame = () => {
    if (username.replace(/ /g, '').length < 3) {
      setError(true);
      setErrorText('Имя пользователя должно содержать не менее 3 символов');
    } else {
      getLevelSettingsByTypeAPI('LEVEL').then((levelSetting) => {
        console.log(user);
        console.log(levelSetting);
        if (levelSetting?.data?.levelId) {
          const request = {
            id: user.id,
            username,
            levelId: levelSetting.data.levelId,
          };
          checkUserAPI(request)
            .then((response) => {
              console.log(response);
              setError(false);
              setAppMode('GAME');
            })
            .catch(() => {
              console.error('error');
              setError(true);
              setErrorText('Такой пользователь уже существует, введите другое имя');
            });
        }
      });
    }
  };

  return (
    <Animation inherit>
      <MenuContainer>
        <Text fontSize="24px" style={{ marginBottom: '40px' }} color={COLORS.blue1}>
          Введите имя пользователя
        </Text>
        <input className={classes.usernameinput} style={{ marginBottom: '2px' }} id="username" value={username} onChange={usernameOnChange} />

        {error && (
          <Text fontSize="16px" style={{ marginBottom: '20px' }} color={COLORS.red1}>
            {errorText}
          </Text>
        )}

        <Button text="Играть" style={{ width: '200px', marginTop: '20px', marginBottom: '20px' }} onClick={startGame} />
      </MenuContainer>
    </Animation>
  );
});

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
