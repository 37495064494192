import axios from 'axios';

export const axs = axios;

export const config = (user, isFormData = false) => {
  const headers = {
    authorization: `Bearer ${user?.token}`,
  };
  if (isFormData) {
    headers['Content-Type'] = 'multipart/form-data';
  } else {
    headers['Content-Type'] = 'application/json; charset=UTF-8';
  }
  return {
    headers,
  };
};

export const interceptor = (api) => {
  api.interceptors.request.use(
    async (cfg) => {
      cfg.headers['Access-Control-Allow-Origin'] = `*`;
      return cfg;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};

export const createPathVariables = (request) => {
  if (!request) return '';
  const params = Object.keys(request)
    .map((objectKey) => `${objectKey}=${request[objectKey]}`)
    .join('&');
  return params.length > 0 ? `?${params}` : '';
};

export const apiProvider = (api, fullData = false) => {
  return {
    request({ method, endpoint, request = {}, cfg = {}, checkAuth = true }) {
      try {
        let req = request;
        if (method === 'get' || method === 'delete') req = cfg;
        // return api[method](endpoint, method === 'post' ? JSON.stringify(req) : req, cfg)
        return api[method](endpoint, req, cfg)
          .then((r) => {
            if (r.status === 200) return fullData ? Promise.resolve(r) : Promise.resolve(r.data);
            return Promise.reject(r);
          })
          .catch((e) => {
            let status;
            let message;
            let error;
            if (e?.response?.data) {
              status = e.response.data?.status;
              message = e.response.data?.message;
              error = e.response.data?.error;
            } else {
              try {
                if (e?.message === 'cancel') {
                  status = 205;
                  message = 'CANCEL';
                } else {
                  status = +e.toString().match(/status code (\d+)/)[1];
                }
                if (process.env.REACT_APP_DEBUG_CONSOLE === 'true') {
                  console.log(status);
                  console.log(checkAuth);
                }
              } catch {
                status = 406;
                message = 'ERROR';
              }
            }
            switch (status) {
              case 401:
                if (process.env.REACT_APP_DEBUG_CONSOLE === 'true') console.log('auth failed');
                break;
              case 403:
                if (process.env.REACT_APP_DEBUG_CONSOLE === 'true') console.log('token invalid,logout');
                // window.location.href = '/';
                break;
              default:
                break;
            }
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({ status, message, error });
          });
      } catch (reason) {
        return Promise.reject(reason);
      }
    },
  };
};
