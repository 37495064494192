import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { ICONS_PACK, iconsPack } from './params/iconsPack';
import classes from './css/Icon.module.scss';
import { COLORS } from '../_settings/colors';

const IconSVG = observer(function IconSVG({ name, size, fillColor, inheritSize, style }) {
  const [styleState] = React.useState({
    style,
    height: `${inheritSize && !size ? 'inherit' : `${size}px`}`,
    width: `${inheritSize && !size ? 'inherit' : `${size}px`}`,
  });

  if (!iconsPack[name]) return null;
  const functionIcon = typeof iconsPack[name] === 'function';

  return (
    <div className={classes.iconSvg} style={styleState}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        preserveAspectRatio="xMinYMin"
        viewBox="0 0 24 24"
        fill={functionIcon ? 'none' : fillColor}
        style={{ height: 'inherit', width: 'inherit' }}
      >
        {functionIcon ? iconsPack[name](fillColor) : iconsPack[name]}
      </svg>
    </div>
  );
});

IconSVG.propTypes = {
  name: PropTypes.oneOf(Object.values(ICONS_PACK)).isRequired,
  inheritSize: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)]),
  size: PropTypes.oneOf(['', '16', '18', '20', '24', '28', '32', '36', '54']),
  fillColor: PropTypes.oneOfType([PropTypes.oneOf(Object.values(COLORS)), PropTypes.arrayOf(PropTypes.oneOf(Object.values(COLORS)))]),
};

IconSVG.defaultProps = {
  inheritSize: true,
  style: null,
  size: '',
  fillColor: COLORS.green1,
};

export { IconSVG };
