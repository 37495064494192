import React from 'react';
import PropTypes from 'prop-types';
import classes from './css/objectRect.module.scss';

function ObjectRect({ coordinateX, coordinateY, modify, width, height }) {
  const [style, setStyle] = React.useState({});
  React.useEffect(() => {
    if (!modify) return;
    setStyle({ left: coordinateX, top: coordinateY, width: `${width}px`, height: `${height}px` });
  }, [coordinateX, coordinateY, modify, width, height]);

  if (!modify) return null;
  return <div className={classes.objectRect} style={style} />;
}

ObjectRect.propTypes = {
  coordinateX: PropTypes.number,
  coordinateY: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  modify: PropTypes.bool,
};

ObjectRect.defaultProps = {
  coordinateX: 0,
  coordinateY: 0,
  width: 0,
  height: 0,
  modify: false,
};

export default ObjectRect;
