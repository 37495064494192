import PropTypes from 'prop-types';
import classes from './css/loadScreen.module.scss';

function LoadScreen({ show, title }) {
  if (!show) return null;
  return (
    <section className={classes.loadScreen}>
      <div className={classes.title}>Уровень загружается</div>
      {title && <div className={classes.desc}>{title}</div>}
    </section>
  );
}

LoadScreen.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
};

LoadScreen.defaultProps = {
  show: false,
  title: null,
};
export default LoadScreen;
