import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/modal/Modal';
import { dialogStore } from '../../../../common/stores/DialogStore';
import { Levels } from '../../../../common/entity/Levels';
import Button from '../../../../components/buttons/Button';
import lodashMethods from '../../../../common/utils/lodashUtils';
import LevelDataClass from '../../../../common/entity/LevelDataClass';
import Box from '../../../../components/containers/Box';
import Text from '../../../../components/gameItems/Text';
import { deleteLevelByIdAPI, getAllLevelAPI } from '../../../../common/providers/editorApi';
import { notify } from '../../../../components/notify/notitfication';
import { appStore } from '../../../../common/stores/AppStore';

const DialogLoadLevel = observer(function Dialog({ onSelect }) {
  const { dialogState, setDialogState } = dialogStore || {};
  const { apiKey } = appStore || {};
  const [levelsTemplate, setLevelsTemplate] = React.useState([]);
  const [loadedDataLevels, setLoadedDataLevels] = React.useState([]);

  React.useEffect(() => {
    if (dialogState?.type === 'NEW') {
      setLevelsTemplate(Levels.map((_m) => ({ levelId: _m?.levelId, name: `template_${_m?.levelId}` })));
    } else if (dialogState?.type === 'LOAD') {
      getAllLevelAPI({ apiKey })
        .then((response) => {
          setLoadedDataLevels((response ?? []).map((_m) => new LevelDataClass(_m)));
          setLevelsTemplate((response ?? []).map((_m) => ({ id: _m?.id, levelId: _m?.data?.levelId, name: _m?.name })));
        })
        .catch(() => notify({ text: 'Неверный ключ доступа', type: 'error' }));
    }
  }, [dialogState, apiKey]);

  const onClickLevel = ({ id = null, levelId, name }) => {
    const level = lodashMethods.cloneDeep(Levels).find((_f) => _f?.levelId === levelId);
    let levelData = null;
    if (dialogState?.type === 'LOAD') {
      levelData = loadedDataLevels.find((_f) => _f?.id === id);
      const existIds = levelData.data.gameObjects.map((_m) => _m?.id);
      level.gameObjects = level.gameObjects.filter((_f) => !existIds.includes(_f?.id));
    }
    if (!levelData) {
      levelData = new LevelDataClass({ name, data: { levelId, backImage: level?.backImage } });
    }
    onSelect({ level, data: levelData });
    closeDialog();
  };

  const closeDialog = () => {
    setDialogState(null);
    setLevelsTemplate([]);
  };

  const onDeleteLevel = (id) => {
    deleteLevelByIdAPI({ id, apiKey })
      .then(() => {
        setDialogState(lodashMethods.cloneDeep(dialogState));
      })
      .catch(() => notify({ text: 'Неверный ключ доступа', type: 'error' }));
  };

  return (
    <Modal
      title={dialogState?.type === 'NEW' ? 'Выбор шаблона' : 'Выбор уровня'}
      show={dialogState?.type === 'NEW' || dialogState?.type === 'LOAD'}
      width="660px"
      onCancel={closeDialog}
    >
      <Box direction="column">
        {levelsTemplate.map((_m) => (
          <Box direction="row" justify="space-between" key={_m?.id} align="center" spacing="0 0 12px 0">
            <Text>{_m?.id ?? _m?.levelId}</Text>
            <Text>{_m?.name}</Text>
            <Box spacing="0 8px 0 0">
              <Button text="Выбрать" onClick={() => onClickLevel({ id: _m?.id, levelId: _m?.levelId, name: _m?.name })} />
              {dialogState?.type === 'LOAD' && <Button text="Удалить" onClick={() => onDeleteLevel(_m?.id)} />}
            </Box>
          </Box>
        ))}
      </Box>
    </Modal>
  );
});

DialogLoadLevel.propTypes = {
  onSelect: PropTypes.func,
};

DialogLoadLevel.defaultProps = {
  onSelect: Function.prototype,
};

export default DialogLoadLevel;
