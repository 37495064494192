import PropTypes from 'prop-types';

class LevelSettings {
  constructor(inDto) {
    this.levelId = inDto?.levelId;
    this.levelAttempts = inDto?.levelAttempts;
  }
}

LevelSettings.propTypes = {
  levelId: PropTypes.number,
  levelAttempts: PropTypes.number,
};
export default LevelSettings;
